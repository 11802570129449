import React from "react";
import { ContactCards } from "sharedComponents";

const ContactInfos = () => {
	return (
		<div className="flex w-full justify-center bg-[#F6F8FC] px-[36px] py-[48px] lg:left-0 lg:py-[31px] lg:px-[32px] xl:py-[60px]">
			<div className="flex w-full flex-col gap-[20px]  lg:max-w-[960px] lg:flex-row lg:items-center xl:max-w-[1320px]">
				<div className="lg:w-1/2">
					<h2 className="m-0 text-center text-[39px] leading-[48px] lg:text-left">
						Contactez-nous
					</h2>{" "}
				</div>
				<div className="flex w-full flex-col gap-[20px] md:flex-row xl:justify-end">
					<ContactCards
						imageScr="/assets/images/pictos/box.svg"
						message="Envoyez-nous un email"
						contactInfo="contact@acses.io"
						className="flex h-[106px] w-full items-center justify-start bg-[#FFFFFF] p-[20px] xl:max-w-[288px]"
					/>
					<ContactCards
						imageScr="/assets/images/pictos/headphones.svg"
						message="Appelez-nous"
						contactInfo="09 80 80 05 51"
						className="flex h-[106px] w-full items-center justify-start bg-[#FFFFFF] p-[20px] xl:max-w-[288px]"
					/>
				</div>
			</div>
		</div>
	);
};

export default ContactInfos;
