import React from "react";
import { Link } from "react-router-dom";
import Card from "../../sharedComponents/Card";

const Step2 = () => {
	return (
		<Card
			img={
				<>
					<img
						className="md:hidden"
						src="/assets/images/myCustomMasterKey/custom-mobile.webp"
						alt=" habillage personnalisé des casiers en métal"
						loading="lazy"
					/>
					<img
						className="hidden md:flex lg:hidden"
						src="/assets/images/myCustomMasterKey/custom-tablette.webp"
						alt=" habillage personnalisé des casiers en métal"
						loading="lazy"
					/>
					<img
						className="hidden lg:flex xl:hidden relative -bottom-[40px]"
						src="/assets/images/myCustomMasterKey/custom-desktop.webp"
						alt=" habillage personnalisé des casiers en métal"
						loading="lazy"
					/>
					<img
						className="hidden xl:flex relative -bottom-[40px]"
						src="/assets/images/myCustomMasterKey/custom-desktop-large.webp"
						alt=" habillage personnalisé des casiers en métal"
						loading="lazy"
					/>
				</>
			}
			subtitle="Habillage"
			title="Personnalisez l’habillage de vos consignes connectées"
		>
			<div className="">
				{" "}
				<div className="md:flex mb-[20px]">
					<p className="text-[#163F59] font-semibold mb-[8px] md:w-1/3 lg:w-[248px]">
						Personnalisez vos consignes aux couleurs de
						votre entreprise et …
					</p>{" "}
				</div>{" "}
				<div>
					<ul className="flex flex-col gap-[12px] md:gap-[24px] mb-[20px] md:mb-[36px] pl-[25px] lg:pl-[20px]">
						<li
							style={{
								listStyleImage:
									"url('assets/images/pictos/blue-tcheck.svg')",
							}}
							className="text-[#163F59] pl-[10px]"
						>
							Améliorez l’expérience de vos clients
						</li>
						<li
							style={{
								listStyleImage:
									"url('assets/images/pictos/blue-tcheck.svg')",
							}}
							className="text-[#163F59] pl-[10px]"
						>
							Gagnez en visibilité
						</li>
						<li
							style={{
								listStyleImage:
									"url('assets/images/pictos/blue-tcheck.svg')",
							}}
							className="text-[#163F59] pl-[10px]"
						>
							Différenciez-vous de la concurrence
						</li>
					</ul>
				</div>
				<p className="small-text">
					Vous avez une idée de design… ou pas du tout ?
					Notre graphiste vous accompagne dans votre projet.
					Il vous propose un habillage de votre{" "}
					<Link to="/comment-ca-marche"><span className="text-[#3798D4] underline">consigne sécurisée</span></Link> avec votre logo et
					vos couleurs. Nous mettons tout en oeuvre pour que
					votre consigne reflète votre identité de marque !
				</p>
			</div>
		</Card>
	);
};

export default Step2;
