import React from "react";
import { v4 as uuidv4 } from "uuid";

const Actions = () => {
	const services = [
		{
			id: uuidv4(),
			imgScr: "/assets/images/pictos/finger-print.svg",
			imgStyle: "w-[36px]",
			alt: "",
			text: "Traçabilité de vos clés",
		},
		{
			id: uuidv4(),
			imgScr: "/assets/images/pictos/remote-hexagone.svg",
			imgStyle: "w-[36px]",
			alt: "",
			text: "Interface de gestion à distance",
		},
		{
			id: uuidv4(),
			imgScr: "/assets/images/pictos/padlock.svg",
			imgStyle: "w-[32px]",
			alt: "",
			text: "Métal anti-effraction",
		},
		{
			id: uuidv4(),
			imgScr: "/assets/images/pictos/headphones.svg",
			imgStyle: "w-[36px]",
			alt: "",
			text: "Support client 7j/7",
		},
	];

	return (
		<div className="mx-auto mb-[64px] flex w-full flex-col gap-y-[38px] px-[36px] md:flex-row md:flex-wrap md:justify-center md:gap-x-[139px] lg:max-w-[952px] lg:justify-between lg:gap-[43px] xl:max-w-[1320px] ">
			{services.map((service) => (
				<div
					key={service.id}
					className="flex flex-col items-center justify-center gap-[14px] md:w-[192px] lg:w-[120px]"
				>
					<div className={`${service.imgStyle} h-[44px]`}>
						{" "}
						<img
							className="w-full"
							src={service.imgScr}
							alt={service.alt}
							width={36}
							height={42}
							loading="lazy"
						/>{" "}
					</div>
					<div className="w-[192px]">
						<p className="text-center font-semibold text-[#163F59]">
							{service.text}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default Actions;
