import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../sharedComponents/Button";
import Card from "../../sharedComponents/Card";

const Step3 = () => {
	const navigate = useNavigate();

	return (
		<Card
			img={
				<>
					<img
						className="md:hidden"
						src="/assets/images/myCustomMasterKey/ordi-api-mobile.webp"
						alt="système acses compatible avec tous les DMS et CRM"
						loading="lazy"
					/>
					<img
						className="hidden md:flex lg:hidden"
						src="/assets/images/myCustomMasterKey/ordi-api-tablette.webp"
						alt="système acses compatible avec tous les DMS et CRM"
						loading="lazy"
					/>
					<img
						className="hidden lg:flex xl:hidden relative -bottom-[40px] lg:-bottom-[150px]"
						src="/assets/images/myCustomMasterKey/ordi-api-desktop.webp"
						alt="système acses compatible avec tous les DMS et CRM"
						loading="lazy"
					/>
					<img
						className="hidden xl:flex relative xl:-bottom-[90px] left-[70px]"
						src="/assets/images/myCustomMasterKey/ordi-api-desktop-large.webp"
						alt="système acses compatible avec tous les DMS et CRM"
						loading="lazy"
					/>
				</>
			}
			subtitle="Plateforme de gestion"
			title="Connectez votre DMS, CRM ou appli mobile à notre système Acses"
			
		>
				<div className="">
			{" "}
			<div>
			<div className="mb-[40] md:mb-[36px]">
				<div className="mb-[20px]">
					<div className="flex flex-col gap-[20px] lg:gap-[16px] mb-[26px] md:mb-[36px] lg:mb-[32px]"> 
						<div>
						<p className="font-semibold text-[#163F59]">API ouverte et documentée</p>{" "}
						<p className="small-text">
							Connectez rapidement votre armoire à clés
							(lien vers la page hôtellerie) à vos logiciels
							internes de gestion et vos plateformes de
							réservations clients et utilisateurs.
						</p>
					</div>
					<div>
						<p className="font-semibold text-[#163F59]">Compatible avec tous les DMS et CRM</p>{" "}
						<p  className="small-text">
							Un chef de projet technique vous accompagne
							dans l'intégration de la solution avec vos
							outils existants.
						</p>
					</div>
					</div>
					
					<Link to="/blog/optimisation-parcours-client-"><p className="text-[#3798D4] font-semibold lg:text-[17px]">Découvrez les consignes personnalisées Midas</p></Link>
				</div>{" "}
			</div>
				<div className="flex flex-col md:flex-row md:items-center items-start gap-[16px]">
					<Button
						text="Obtenir un devis"
						action={() => navigate("/demande-devis")}
					/>
					<Button
						text="Contacter un commercial"
						action={() => navigate("/contact")}
						reverseStyle
					/>
				</div>
			</div>
		</div>
		</Card>
	);
};

export default Step3;
