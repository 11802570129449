import React from "react";
import { ContactCards } from "../ContactCards";
import i18next from "i18n";
import { Trans, useTranslation } from "react-i18next";
import en from "./Carded-i18n/en";
import fr from "./Carded-i18n/fr";

i18next.addResourceBundle("en", "CARDED_HOME", en);
i18next.addResourceBundle("fr", "CARDED_HOME", fr);

export const Carded = () => {
  const { t } = useTranslation("CARDED_HOME");
  return (
    <div className="relative hidden w-full flex-col items-center md:flex lg:max-w-[976px] xl:mx-[32px] xl:max-w-[1320px]">
      <div className="h-[433px] w-full rounded-2xl bg-[#163F59] p-[40px] pb-[150px] lg:h-[460px] xl:h-[500px] xl:p-[80px] xl:pt-[60px]  3xl:pb-[180px] ">
        <div className="flex ">
          <h3 className="mr-[48px] w-1/5  font-bold text-white md:w-[175px] lg:mr-[32px] lg:w-[297px] xl:mr-[113px] xl:w-[410px]">
            <Trans t={t} i18nKey="H3">
              Spécialement
              <br className="xl:hidden" /> conçu pour{" "}
              <br className="lg:hidden" /> gérer vos clés{" "}
              <br className="lg:hidden" /> en toute simplicité
            </Trans>
          </h3>

          <div className="w-3/5 md:w-[416px] lg:w-[543px] xl:w-[482px]">
            <p className="mb-[27px] text-white">{t("WITH_MAEVA")}</p>
            <div className="mb-[30px]">
              <div className="flex items-start text-white">
                <img
                  className="md:w-[40px] lg:w-[59px]"
                  src="/assets/images/team/laure.D.webp"
                  alt=""
                  width={40}
                  height={40}
                  loading="lazy"
                />
                <div className="ml-[24px]">
                  <p className="font-bold text-white">Laure D.</p>
                    <p className="small-text text-white">
                  <Trans t={t} i18nKey="MAEVAHOME">
                      Responsable marketing et expérience clients, <br /> chez
                      MaevaHome
                  </Trans>
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" shadowBoxCarded bottom-[200px] -mt-[150px] w-10/12 rounded-2xl bg-white p-[40px] md:w-11/12 lg:w-9/12 xl:py-[60px] 3xl:w-9/12">
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-center md:mb-[32px]">
            <Trans t={t} i18nKey="H3_ADOPT" >
            <h3 className="text-center font-semibold text-[#163F59] xl:w-[630px]">
              Vous souhaitez adopter notre solution <br className="xl:hidden" />{" "}
              pour faciliter votre activité ?
            </h3>
            </Trans>
          </div>
          <div className="flex w-full max-w-[637px] flex-col items-center justify-center md:flex-row md:gap-[20px] lg:gap-x-[32px]">
            <ContactCards
              imageScr="/assets/images/pictos/box.svg"
              message={t("TYPE_MESSAGE_1")}
              contactInfo={t("EMAIL")}
              className="flex w-full items-center justify-start p-[20px]"
            />
            <ContactCards
              imageScr="/assets/images/pictos/headphones.svg"
              message={t("TYPE_MESSAGE_2")}
              contactInfo={t("PHONE")}
              className="flex w-full items-center justify-start p-[20px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
