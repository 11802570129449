import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "sharedComponents/Button";

const NewsLettersBlog = () => {
	const [messageStyle, setMessageStyle] = useState("");
	const [messageForm, setMessageForm] = useState("");
	const [success, setSuccess] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm();

	const onSubmit = async (dataForm) => {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				source: "Newsletter",
				...dataForm,
				newsletter: true,
			}),
		};

		fetch("https://api-v2.acses.io/web/contact/source", options)
			.then((res) => {
				if (res.status === 200) {
					setMessageStyle("text-[#33A620]")
					setSuccess(true);
					console.log(res.data)
					setMessageForm(
						"Email envoyé avec succès"
					);
				} else if (res.status === 500) {
					setMessageForm(
						"Une erreur est survenue, veuillez réessayer ultérieurement"
					);
					setTimeout(() => setMessageForm(""), 5000)
				} else {
					setMessageForm(
						"Une erreur est survenue, veuillez réessayer ultérieurement"
					);
					setTimeout(() => setMessageForm(""), 5000)
				}
			})
			.catch((err) => console.log(err));
		reset();
	};

	return (
		<section className="w-full lg:max-w-[960px] xl:max-w-[1320px] px-[36px] lg:px-0">
			<div>
				<div className="lightblue-shadow rounded-[16px] bg-white p-[20px] md:flex md:gap-[16px] md:p-[40px] lg:gap-[20px] xl:gap-[48px] xl:py-[80px] xl:px-[68px]">
					<div className="mb-[8px] w-full max-w-[52px] lg:max-w-[83px] xl:max-w-[107px]">
						<img
							src="/assets/images/blog/acses-logo.svg"
							alt=""
							className="w-[52px] lg:w-[83px] xl:w-[107px]"
						/>
					</div>
					<div className="lg:flex lg:gap-[36px] xl:gap-[126px]">
						<div className="mb-[36px] lg:mb-0 lg:w-[367px]">
							<h2 className="mb-[8px] text-[33px] lg:mb-[24px] lg:text-[39px]">
								Abonnez-vous à notre newsletter
							</h2>
							<p className="text-[17px] leading-[34px] lg:text-[20px]">
								pour recevoir en exclusivité toute notre
								actualité et les dernières études de cas
								client.
							</p>
						</div>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="lg:w-1/2 xl:pt-[36px]"
						>
							<div className="items-center md:flex md:gap-[8px]">
								<input
									{...register("email", {
										required: true,
										pattern:
											/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
									})}
									// style={{backgroundImage:errors.helpMotif && "url('assets/images/pictos/error-form.svg')",backgroundRepeat:"no-repeat",backgroundPosition:"calc(100% - 18px)"}}
									className={`mb-[8px] h-[48px] w-full rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] text-[.74rem] md:mb-0 md:text-[100%]`}
									placeholder="Entrez votre adresse mail"
								/>
								{errors?.email?.type === "pattern" && (
									<p className="text-[#EE2D2D]">
										adresse email saisie n'est pas conforme
									</p>
								)}

								<Button
									text="Envoyer"
									addStyle="font-normal w-full md:max-w-[98px]"
								/>
							</div>
							<div>
								<span
									className={
										messageStyle +
										" mb-[36px] mt-[8px] font-semibold leading-[24px]"
									}
								>
								{success && messageForm}
							</span>
							</div>
							<div className="mb-[20px] mt-[8px] flex items-start gap-x-[8px]">
								<input
								{...register("newsletter")}
									className="mt-[5px]"
									type="checkbox"
									required
								/>
								<p className="small-text leading-[24px] text-[#506F83]">
									En soumettant ce formulaire, j’accepte que
									mes informations soient utilisées
									exclusivement dans le cadre de ma demande
									et de la relation commerciale éthique et
									personnalisée qui pourrait en découler si
									je le souhaite.
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NewsLettersBlog;
