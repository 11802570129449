import React, { useEffect } from "react";
import ActivityArea from "./components/ActivityArea/ActivityArea";
import AdoptSolution from "./components/AdoptSolution/AdoptSolution";
import Contact from "./components/Contact/Contact";
import Hero from "./components/Hero/Hero";
import HowItWorks from "./components/HowitWorks/HowItWorks";
import NewsLetterForm from "./components/NewsLetterForm/NewsLetterForm";
import Partners from "./components/Partners/Partners";
import Services from "./components/Services/Services";
import WhyAcses from "./components/WhyAcses/WhyAcses";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

const Home = (props) => {
  const tagManagerArgs = {
    dataLayer: {
      events: "info",
      page_category_1: props.path,
      page_category_2: "{{page_category_2}}",
    },
    dataLayerName: "PageDataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);
  return (
    <div
      style={{
        backgroundImage:
          "url('/assets/images/home/page-motif-background.webp')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "contain",
      }}
      className="relative overflow-hidden"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sécurité, traçabilité et gestion à distance de vos clés !</title>
        <meta
          name="description"
          content="Vous souhaitez optimiser la gestion de vos clés ? Découvrez nos consignes connectées et sécurisées en cliquant ici !"
        />
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href="/assets/images/home/homepage-hero-desktop-large2.webp"
          type="image/webp"
        ></link>
      </Helmet>
      <section className="flex justify-center md:mb-[50px] md:pt-[90px] lg:mb-auto">
        <div className="absolute right-0 top-0 hidden w-[70%] overflow-hidden lg:flex xl:hidden">
          <img
            className="relative xl:h-auto"
            src="/assets/images/home/home-hero-desktop2.webp"
            alt="- consigne à clés connectées"
            width={936.594}
            height={846.391}
            loading="lazy"
          />
        </div>
        <Hero path={props.path} />
      </section>
      <section className="4xl:mt-[220px]">
        <Partners />
      </section>
      <section>
        <AdoptSolution />
      </section>
      <section>
        <WhyAcses />
      </section>
      <section className="h-[740px] bg-[#F6F8FC] py-[40px] pl-[36px] md:h-[758px] lg:flex lg:h-[982px] lg:justify-center lg:py-[96px] lg:pl-[32px] xl:bg-transparent xl:pl-0">
        <ActivityArea />
      </section>
      <section>
        <div className="mb-[180px] hidden justify-center px-[36px] lg:mb-[60px] lg:flex ">
          <Services />
        </div>
        <div className="mb-[96px] flex justify-center px-[36px] md:mb-[20px] lg:px-[32px]">
          <HowItWorks />
        </div>
        <div className="px-[36px]">
          <Contact />
        </div>
      </section>
      <NewsLetterForm />
    </div>
  );
};

export default Home;
