const locale ={
    TITLE:`<0><0>Why Acses ?</0><1>To automate the delivery of your keys 24/7</1></0> <0><0>Why Acses ? </0 ><1>To automate the delivery of your keys 24/7</1></0>`,
    FONTIONALITY_TITLE1:"Safe storage",
    FONTIONALITY_BODY1:"keys and badges in your establishments under management",
    FONTIONALITY_TITLE2:"Remote programming",
    FONTIONALITY_BODY2:"via our Acses Manager software",
    FONTIONALITY_TITLE3:"Burglar proof",
    FONTIONALITY_BODY3:"burglar-proof metal lockers, made in France",
    FONTIONALITY_TITLE4:"Key traceability",
    FONTIONALITY_BODY4:"and real-time monitoring",
    FONTIONALITY_TITLE5:"Quick integration",
    FONTIONALITY_BODY5:"An open API makes it easy to integrate with your system",
}
export default locale