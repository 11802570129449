import React from "react";
import Card from "../../sharedComponents/Card";

const Step4 = () => {
	return (
		<Card
			img={
				<>
					<img
						className="md:hidden"
						src="/assets/images/myCustomMasterKey/ihm-mobile.webp"
						alt="digitalisation de votre parcours client"
						loading="lazy"
					/>
					<img
						className="hidden md:flex lg:hidden relative bottom-[60px]"
						src="/assets/images/myCustomMasterKey/ihm-tablette.webp"
						alt="digitalisation de votre parcours client"
						loading="lazy"
					/>
					<img
						className="hidden lg:flex xl:hidden relative -bottom-[40px]"
						src="/assets/images/myCustomMasterKey/ihm-desktop2.webp"
						alt="digitalisation de votre parcours client"
						loading="lazy"
					/>
					<img
						className="hidden xl:flex relative left-[80px]"
						src="/assets/images/myCustomMasterKey/ihm-desktop-large2.webp"
						alt="digitalisation de votre parcours client"
						loading="lazy"
					/>
				</>
			}
			subtitle="IHM"
			title="Personnalisez le parcours utilisateur de vos écrans tactiles"
		>
			<div className="">
				{" "}
				<div className="flex flex-col gap-[16px]">
					<div>
						<p className="small-text">
							Nous définissons ensemble{" "}
							<span className="font-semibold">
								la digitalisation de votre parcours
								client
							</span>
							, en créant les champs de saisies dont vous
							avez besoin directement intégrés sur les
							écrans tactiles des machines.
						</p>
					</div>
					<div>
						<p className="small-text">
							<span className="font-semibold">Vos fonctionnalités métier</span> sont
							ainsi adaptées au parcours que vous proposez à
							vos utilisateurs.
						</p>
					</div>
					<div>
						<p className="small-text">
							Toutes ces informations sont ensuite
							directement collectées dans{" "}
							<span className="font-semibold">votre interface de gestion</span>.
						</p>
					</div>
				</div>{" "}
			</div>
		</Card>
	);
};

export default Step4;
