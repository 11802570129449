import { gsap } from "gsap";
import React, { useLayoutEffect } from "react";

const APIDoc = () => {

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			gsap.from("#img", {
				y: 100,
				opacity:0,
				duration: 1.5,
				ease: "power3.Out",
				scrollTrigger: {
					trigger: "#img",
					start:"center bottom",
			
				},
			});

		});
		return () => ctx.revert();
	}, []);

	return (
		<div className="relative md:w-1/2 lg:w-full lg:max-w-[1320px] xl:w-full">
			<div>
				<div className="mb-8px">
					<p className="subtitle text-[#2DBCEE]">
						Une API ouverte & documentée
					</p>
				</div>
				<div className="lg:w-[585px] xl:w-[640px]">
					<h2 className="mb-[40px] text-white">
						Interfaçage via une API ouverte & documentée
					</h2>
				</div>
				<div className="md:mb-[36px] lg:w-[475px] xl:w-[647px]">
					<p className="text-[1.063rem] font-normal text-white lg:font-normal lg:leading-[32px] xl:max-w-[529px]">
						Grâce à notre API, connectez facilement votre
						système à notre plateforme de{" "}
						<span>
							gestion de casiers.
						</span>{" "}
						Bénéficiez de l’intégration rapide à votre CRM
						/ PMS. Un chef de projet de notre équipe de
						développement vous accompagnera tout au long du
						processus de connexion.
					</p>
				</div>
				<div id="img" className="top-[220px] mt-[10px] mb-[36px] w-fit md:absolute md:right-0 md:hidden lg:-right-[32px]  lg:top-[40px] lg:flex xl:-top-[60px]">
					<img
						className="lg:w-[450px] xl:hidden"
						src="/assets/images/howItWorks/mobile-code.webp"
						alt=""
						width={360}
						height={293}
						loading="lazy"
						
					/>
					<img
						className="hidden lg:w-[450px] xl:flex xl:w-[690px]"
						src="/assets/images/howItWorks/phone-code-desktop.png"
						alt=""
						width={654}
						height={480}
						loading="lazy"
					/>
				</div>
				<div>
					{/* <button
						id="bouton-ccm-telechargez-documentation-api"
						className="flex h-[72px] items-center rounded-lg border border-white py-[12px] pl-[12px] pr-[16px] text-[16px] font-bold text-white md:h-[48px] md:w-[338px]"
					>
						Téléchargez notre documentation{" "}
						<img
							className="ml-[10px]"
							src="/assets/images/pictos/arrow-button.svg"
							alt="flèche"
							width={7}
							height={10}
						/>
					</button> */}
				</div>
			</div>
		</div>
	);
};

export default APIDoc;
