import React from "react";

const Security = () => {
	return (
		<div className="lg:flex lg:flex-col w-full lg:max-w-[960px] xl:max-w-[1320px] ">
			<div className="flex flex-col md:items-center ">
				{" "}
				<p className="subtitle relative md:text-center text-[#3798D4] font-semibold mb-[8px] lg:min-w-[250px] pl-[36px] md:p-0">
					Securité
				</p>
				<div className="flex justify-center">
					<h2 className="text-[33.2px] lg:text-[1.953rem] leading-[40px] lg:leading-[48px] md:text-center px-[36px] md:w-[467px] lg:w-[706px] text-white font-semibold lg:pl-[36px] lg:mb-[60px]">
						Sécurité garantie pour la gestion des clés de
						votre entreprise
					</h2>
				</div>
			</div>
			<div className="px-[36px] lg:px-0 md:flex md:flex-col lg:flex-row md:items-center lg:items-center lg:gap-[51px]">
				<div className="mb-[40px] lg:w-1/2">
					<img
						className="w-full xs:hidden"
						src="/assets/images/myCustomMasterKey/woman-shield.webp"
						alt="casiers intelligents sécurisés"

						loading="lazy"
					/>
						<img
						className="hidden w-full xs:flex md:hidden"
						src="/assets/images/myCustomMasterKey/woman-shield-desktop.webp"
						alt="casiers intelligents sécurisés"
						loading="lazy"
					/>
					<img
						className="hidden md:flex lg:hidden"
						src="/assets/images/myCustomMasterKey/woman-shield-tab.webp"
						alt="casiers intelligents sécurisés"
						loading="lazy"
					/>
					<img
						className="hidden lg:flex "
						src="/assets/images/myCustomMasterKey/woman-shield-desktop.webp"
						alt="casiers intelligents sécurisés"
						loading="lazy"
					/>
				</div>
				<div className="flex flex-col md:flex-row lg:flex-col gap-[16px] md:gap-[29px] lg:gap-[32px] lg:w-1/2 lg:max-w-[450px]">
					<p className="md:w-[251px] lg:w-auto text-[1rem] text-white lg:text-left">
						La sécurité de{" "}
						<span className="">
							vos casiers intelligents
						</span>{" "}
						est assurée à plusieurs niveaux :
					</p>
					<ul
						style={{
							listStyleImage:
								"url('assets/images/pictos/blue-tcheck.svg')",
						}}
						className="pl-[30px] lg:pl-[24px]"
					>
						<li className="text-white mb-[20px]">
							<div className="pl-[27px]">
								<p className="lg:w-[413px] lg:text-[.8rem] text-white font-semibold">
									Serrures électroniques anti-effraction
								</p>
								<p className="small-text text-white">
									Haute résistance, avec détecteur de
									position et alerte électronique de
									forçage.
								</p>
							</div>
						</li>
						<li className="text-white mb-[20px]">
							<div className="pl-[27px]">
								<p className="lg:w-[413px] lg:text-[.8rem] text-white font-semibold">
									Certification serrures 
								</p>
								<p className="small-text text-white">
									CE; ISO9001; RoHS; IAF
								</p>
							</div>
						</li>
						<li className="text-white mb-[20px]">
							<div className="pl-[27px]">
								<p className="lg:w-[413px] lg:text-[.8rem] text-white font-semibold">
									Connexion machine
								</p>
								<p className="small-text text-white">
									garantie par 3G/4G multi opérateurs / WIFI
									/ RJ45
								</p>
							</div>
						</li>
						<li className="text-white mb-[20px]">
							<div className="pl-[27px]">
								<p className="lg:text-[.8rem] lg:w-[413px] text-white font-semibold">
									Remontées d'informations logicielles
								</p>
								<p className="small-text text-white">
									sur tout forçage serrure ou détection
									d'anomalies de sécurité matérielle.
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Security;
