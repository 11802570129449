import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/fr";

const SamePost = ({post}) => {
	return (
		<Link 
		to={`/blog/${post.slug}`}
		>
		<div key={post?.id} className="md:w-[324px] lg:w-[298px] xl:w-[419px]">
			<div className="rounded-[16px] mb-[16px] w-[288px] h-[183px] md:w-[324px] md:h-[205px] lg:w-[298px] xl:w-[419px] xl:h-[292px] overflow-hidden">
				<img className="object-cover h-full" src={post.coverImage?.url} alt="" />
			</div>
			<div>
				<p className="small-text mb-[4px]">{dayjs(post.date).format(
									"DD MMMM YYYY"
								)}</p>
				<h3 className="mb-0">{post?.title} Comment nous avons pensé à gérer les clés à distance ?</h3>
			</div>
		</div>
		</Link>
	);
};

export default SamePost;
