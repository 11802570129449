import React from "react";
import ContactInfos from "./components/ContactInfos/ContactInfos";
import Hero from "./components/Hero/Hero";
import NewsLetterForm from "../Home/components/NewsLetterForm/NewsLetterForm";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import HubspotContactForm from "pages/AskQuotation/components/HubspotContactForm";

const Index = () => {
  const tabletteScreen = useMediaQuery({ query: "(min-width:768px)" });
  const desktopScreen = useMediaQuery({ query: "(min-width:1024px)" });
  const desktopLargeScreen = useMediaQuery({ query: "(min-width:1376px)" });

  const backgroundStyle = {
    backgroundImage: `url("/assets/images/contact-us/${
      (desktopLargeScreen && "background-desktop-large.svg") ||
      (desktopScreen && "background-desktop.svg") ||
      (tabletteScreen && "background-tablette.svg") ||
      (!desktopScreen &&
        !desktopLargeScreen &&
        !tabletteScreen &&
        "background-mobile.svg")
    }")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: desktopScreen || desktopLargeScreen ? "cover" : "contain",
  };
  return (
    <>
      <div
        className="flex justify-center bg-[center_top_75px] pt-[124px] md:bg-[length:562.23px_491.62px] md:bg-[top_80px_right_-80px] md:pt-[120px] lg:bg-[length:1024px_auto] lg:bg-[center_top_55px]  lg:pt-[270px] xl:bg-[length:auto_auto] xl:bg-[center_top_25px]"
        style={backgroundStyle}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Contactez-nous ! - Acses, gestion et protection de vos clés
          </title>
          <meta
            name="description"
            content="Start-up spécialisée dans le contrôle d'accès et les consignes sécurisées. Contactez-nous pour obtenir une démo ou un devis."
          />
        </Helmet>
        <div className="mx-[36px] lg:mx-[32px] lg:grid lg:max-w-[960px] lg:grid-cols-3 xl:max-w-[1320px] xl:grid-cols-2">
          <Hero />
          <div className="mb-[96px] flex min-h-[854px] justify-center lg:order-3 lg:col-span-2 lg:row-span-2 xl:order-2 xl:col-span-1">
            <HubspotContactForm
              region="eu1"
              portalId="26965274"
              formId="68cb26ad-dd31-404a-8d52-8e3041e7d740"
            />
          </div>
          <ContactInfos />
        </div>
      </div>
      <NewsLetterForm parentStyleCustom="" />
    </>
  );
};

export default Index;
