import React, { useContext, useEffect, useState } from "react";
import Button from "../Button";
import "./CookiesBanner.scss";
import { CookiesDispatchContext } from "CookiesContext";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TagManager from "react-gtm-module";

const ToggleCookiesPref = styled((props) => {
  return (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  );
})(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "linear-gradient(180deg, #2970B6 0%, #2DBBED 100%)"
            : "linear-gradient(180deg, #2970B6 0%, #2DBBED 100%)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CookiesBanner = () => {
  const [display, setDisplay] = useState(false);
  const dispatch = useContext(CookiesDispatchContext);
  const [cookies, setCookies, removeCookie] = useCookies();
  const [googleCookies, setGoogleCookies] = useState(true);
  const [hubspotCookies, setHubspotCookies] = useState(true);
  const [allCookies, setAllCookies] = useState();
  const [memorizeCookie, setMemorizeCookie] = useState([]);

  const hubspotCookiesList = [
    "hubspotutk",
    "__hssc",
    "__hssrc",
    "__cf_bm",
    "__hstc",
  ];
  const googleCookiesList = ["_ga_J7FZ2Y0P72", "_ga", "_gcl_au", "_lfa"];

  const cookiesOption = localStorage.getItem("cookiesOption");

  const handleAcceptCookies = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'consentUpdated',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      },
    });
    setDisplay(false);
    dispatch({ payload: true });
    localStorage.setItem("cookiesOption", "AcceptCookies");
  };

  const handleRefuseCookies = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'consentUpdated',
        ad_storage: 'denied',
        analytics_storage: 'denied',
      },
    });
    setDisplay(false);
    dispatch({ payload: false });
    for (let c of googleCookiesList) removeCookie(c);
    for (let c of hubspotCookiesList) removeCookie(c);
    localStorage.setItem("cookiesOption", "NoCookies");
  };

  useEffect(() => {
    process.env.REACT_APP_COOKIES_OPTION === "PROD" && setDisplay(true);
  }, []);

  useEffect(() => {
    if (cookiesOption === "AcceptCookies") {
      setDisplay(false);
    }

    const time = setTimeout(() => {
      if (cookiesOption === "NoCookies") {
        for (let c of googleCookiesList) removeCookie(c);
        for (let c of hubspotCookiesList) removeCookie(c);

        setDisplay(false);
      }
    }, 400);

    return () => clearTimeout(time);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (googleCookies === false) {
        for (let c of googleCookiesList) removeCookie(c);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [googleCookies]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hubspotCookies === false) {
        for (let c of hubspotCookiesList) removeCookie(c);
      } else {
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [hubspotCookies]);

  return (
    display && (
      <div className="overlay">
        <div className="banner">
          <div className="align-center flex flex-col justify-center gap-[36px] lg:flex-row">
            <h6>
              Notre site utilise des cookies pour garantir son bon
              fonctionnement, optimiser ses performances techniques et mesurer
              son audience. Vous pouvez à tout moment retirer votre consentement
              en cliquant sur "refuser".
            </h6>
            <div className="btnContainer">
              <div>
                <Button
                  action={handleAcceptCookies}
                  text="Accepter les cookies"
                />
              </div>
              <div>
                <Button
                  action={handleRefuseCookies}
                  reverseStyle
                  text="refuser"
                />
              </div>
            </div>
          </div>
          <div className="flex w-full max-w-[320px] flex-col gap-x-[36px] xl:max-w-[840px] xl:flex-row">
            {/* <details className="mb-[26px]">
              <summary className="small-text text-[#506f83] underline">
                Mes préférences
              </summary>
              <div className="flex flex-col">
                <FormControlLabel
                  control={<ToggleCookiesPref sx={{ m: 1 }} defaultChecked />}
                  label="Cookies essentiels au bon fonctionement du site"
                  disabled={true}
                />
                <FormControlLabel
                  control={
                    <ToggleCookiesPref sx={{ m: 1 }} defaultChecked={false} />
                  }
                  label="Google Analytics "
                  onChange={(e) => {
                    console.log(e.target.value);
                    setGoogleCookies(!googleCookies);
                  }}
                />
                <FormControlLabel
                  control={
                    <ToggleCookiesPref sx={{ m: 1 }} defaultChecked={false} />
                  }
                  label="Hubspot"
                  onChange={() => setHubspotCookies(!hubspotCookies)}
                />
              </div>
            </details> */}
            <Link
              to="/politique-de-confidentialite"
              onClick={() => setDisplay(false)}
            >
              <p className="small-text font-[300] underline">
                Notre politique de confidentialité
              </p>
            </Link>
          </div>
        </div>
      </div>
    )
  );
};

export default CookiesBanner;

const OTZ = {
  name: "OTZ",
  value: "7042227_48_52_123900_48_436380 ",
  option: { domain: "www.google.com", path: "/", priority: "Medium" },
};
