import React from "react";

const Hero = () => {
  return (
    <div className="relative xl:pt-0 lg:px-0 lg:mb-[10px] w-full ">
      {" "}
      <div className="flex flex-col justify-center xl:mx-0 text-center md:w-[452px] lg:w-[458px] xl:w-[605px]">
        <div className="flex justify-center md:justify-start mb-[20px]">
          <h1 className="font-semibold text-left w-full">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#2DBBED] to-[#2972B8]">
              Des solutions clés en main adaptées
            </span>{" "}
            à votre secteur d'activité !
          </h1>
        </div>
        <div className="flex justify-start mb-[36px] w-full md:w-[452px] ">
        <p className="font-medium text-left text-[#163F59] xl:w-[420px]">
          Recevez un devis personnalisé répondant à vos besoins métier.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
