import React from "react";
import { v4 as uuidv4 } from "uuid";

const Partners = () => {
  
  const partnersMobile = [
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/renault.svg",
      alt: "Logo Renault",
      width:76,
      height:25.79,
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/europcar.svg",
      alt: "Logo Europcar",
      width:94,
      height:24.26,
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/la-poste.svg",
      alt: "Logo La Poste",
      width:104,
      height:34.11,
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/maeva-home.svg",
      alt: "Logo Maeva Home",
      width:124,
      height:25.79,
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/renault.svg",
      alt: "Logo Renault",
      width:76,
      height:25.79,
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/europcar.svg",
      alt: "Logo Europcar",
      width:94,
      height:24.26,
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/la-poste.svg",
      alt: "Logo La Poste",
      width:104,
      height:34.11,
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/maeva-home.svg",
      alt: "Logo Maeva Home",
      width:124,
      height:25.79,
    },
  ];
  const partners = [
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/europcar.svg",
      alt: "europcar",
      width:94,
      height:24.26,
      imgStyle: "w-[94px] lg:w-[123px]",
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/sncf.svg",
      alt: "sncf",
      width:62,
      height:32.87,
      imgStyle: "w-[62px] lg:w-[82px]",
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/carrefour.svg",
      alt: "carrefour",
      width:49,
      height:32.41,
      imgStyle: "w-[49px] lg:w-[64px]",
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/sixt.svg",
      alt: "Sixt",
      width:66,
      height:33,
      imgStyle: "w-[66px] lg:w-[86px]",
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/ratp.svg",
      alt: "Ratp",
      width:27,
      height:31.1,
      imgStyle: "w-[27px] lg:w-[35px]",
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/maeva-home.svg",
      alt: "Maeva Home",
      width:124,
      height:25.79,
      imgStyle: "w-[124px] lg:w-[163px]",
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/la-poste.svg",
      alt: "La Poste",
      width:104,
      height:34.11,
      imgStyle: "w-[104px] lg:w-[136px]",
    },
    {
      id: uuidv4(),
      url: "/assets/images/logos/partners/renault.svg",
      alt: "Renault",
      width:76,
      height:25.79,
      imgStyle: "w-[76px] lg:w-[100px] xl:w-[163px]",
    },
  ];
  return (
    <div className="flex justify-center">
      <div className="flex justify-center">
      <div className="mobilePartners flex flex-wrap md:hidden justify-center items-center gap-x-[40px] gap-y-[57px] mx-[36px]">
       {partnersMobile.map((partner) => (
         <div key={partner.id}>
										
           <img className="w-[100px] md:w-[125px]" src={partner.url} alt={partner.alt} width={partner.width} height={partner.height}  />
         </div>
       ))}
      </div>
      </div>

      <div className="hidden md:flex flex-wrap justify-center md:justify-evenly items-center  gap-x-[57px] lg:gap-x-[96px] xl:gap-x-[174px] mx-[36px] lg:mx-[32px] xl:w-[1089px] md:w-full md:max-w-[715px] lg:max-w-[960px] xl:max-w-[1320px]">
        {partners.map((partner) => (
          <div className={`${partner.imgStyle} mb-[57px]`}  key={partner.id}>
            <img src={partner.url} alt={partner.alt} width={partner.width} height={partner.height} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
