const locale ={
    TITLE:`<0><0>Pourquoi Acses ?</0><1>Pour automatiser la remise de vos clés 24/24h et 7/7j</1></0> <0><0>Pourquoi Acses ?</0><1>Pour automatiser la remise de vos clés 24/24h et 7/7j</1></0>`,
    FONTIONALITY_TITLE1:"Stockage en sécurité",
    FONTIONALITY_BODY1:"des clés et badges dans vos établissements en gestion",
    FONTIONALITY_TITLE2:"Programmation à distance",
    FONTIONALITY_BODY2:"via notre logiciel Acses Manager",
    FONTIONALITY_TITLE3:"Anti-effraction",
    FONTIONALITY_BODY3:"consignes en métal anti-effraction, fabriquées en France",
    FONTIONALITY_TITLE4:"Traçabilité des clés",
    FONTIONALITY_BODY4:"et suivi en temps réel",
    FONTIONALITY_TITLE5:"Intégration rapide",
    FONTIONALITY_BODY5:"Une API ouverte facilite l’intégration à votre système",
}

export default locale