import React from "react";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";

const PlugAndPlaySteps = () => {
	const cardVariants = (duration) => ({
		offscreen: {
			x: 0,
			opacity: 0,
		},
		onscreen: {
			x: 0,
			opacity: 1,
			transition: {
				type: "spring",
				bounce: 0.4,
				duration: duration,
			},
		},
	});

	const steps = [
		{
			id: uuidv4(),
			animId: "step1",
			imgSrc: "/assets/images/pictos/box.svg",
			title: "Configurez un accès",
			content:
				"Dans votre interface de gestion, configurez un accès à la personne de votre choix.",
			width: 38,
			height: 45,
		},
		{
			id: uuidv4(),
			animId: "step2",
			imgSrc: "/assets/images/pictos/box-open.svg",
			title: "Déposez les clés dans le casier",
			content:
				"Déposez vos clés dans le casier choisi dans l'interface de gestion.",
			width: 48,
			height: 48,
		},
		{
			id: uuidv4(),
			animId: "step3",
			imgSrc: "/assets/images/pictos/bubble.svg",
			title: "Envoyez les codes d’accès",
			content:
				"Via l'interface de gestion, envoyez les codes d'accès à l'utilisateur final. ",
			width: 36,
			height: 44,
		},
	];

	return (
		<div className="flex lg:w-1/2 ">
			<div className="mr-[41px] min-h-[658px] min-w-[62px]">
				<img
					src="/assets/images/howItWorks/step3.svg"
					alt="howItWorks"
					width={62}
					height={658}
					loading="lazy"
				/>
			</div>
			<div className="flex flex-col gap-[40px]">
				{steps.map((step) => (
					<motion.div
						key={step.id}
						id={step.animId}
						className="h-[256px] md:w-[598px] lg:w-[320px] xl:w-[496px]"
						initial="offscreen"
							whileInView="onscreen"
							variants={cardVariants(step.animDuration)}
							viewport={{once:true,amount: 0.8 }}
					>
						<div className="mb-[17px]">
							<img
								src={step.imgSrc}
								alt=""
								width={step.width}
								height={step.height}
								loading="lazy"
							/>
						</div>
						<div className="mb-[12px]">
							<h3>{step.title}</h3>{" "}
						</div>
						<div>
							<p className="font-normal text-[#506F83]">
								{step.content}
							</p>{" "}
						</div>
					</motion.div>
				))}
			</div>
		</div>
	);
};

export default PlugAndPlaySteps;
