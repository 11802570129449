import React from "react";
import Hero from "./components/Hero/Hero";
import PartnersBanner from "../AskForDemo/components/PartnersBanner/PartnersBanner";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ContactInfos from "../ContactUs/components/ContactInfos/ContactInfos";
import HubspotContactForm from "./components/HubspotContactForm";

const Index = () => {
  return (
    <>
      <div className="landingPageGradient flex justify-center pt-[36px] lg:pt-[60px] xl:pt-[72px]">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Echange de clés à distance - solution connectée - Consignes Acses
          </title>
          <meta
            name="description"
            content="Casiers électroniques pour dépôt et remise des clés sans contact. Installation facile et rapide. Solution Premium >> Découvrez nos consignes et obtenez un devis"
          />
        </Helmet>
        <section className="mx-[36px] w-full lg:mx-[32px] lg:grid lg:max-w-[1024px] lg:grid-cols-2 xl:max-w-[1320px]">
          <div className="mb-[36px] w-[120px] lg:mb-[48px] lg:w-[201px] xl:mb-[56px]">
            <Link to="/">
              <img
                className="w-full"
                src="/assets/images/logos/logo_acses_with_word_blue.svg"
                alt="logo Acses"
              />
            </Link>
            <div className="hidden lg:mt-[48px] lg:block">
              <Hero />
            </div>
          </div>
          <div className="lg:row-start-2 lg:hidden">
            <Hero />
          </div>
          <div className="min-h-[690px] lg:min-h-[526px] mb-[96px] lg:order-3 lg:col-span-1 xl:order-2">
         
            <HubspotContactForm
              region="eu1"
              portalId="26965274"
              formId="82f3970f-4e4a-4217-a229-f687d5b4813d"
            />
          </div>
          <div className="lg:row-start-3 lg:-mt-[80px]">
            <ContactInfos />
          </div>
        </section>
      </div>
      <div className="flex justify-center bg-[#F6F8FC] py-[40px] px-[36px]">
        <PartnersBanner />
      </div>
    </>
  );
};

export default Index;
