import React from "react";
import { v4 as uuidv4 } from "uuid";

const HowToDriveDesktop = () => {
	const manageInterface = [
		{
			id: uuidv4(),
			imgSrc:
				"/assets/images/pictos/interface-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Une interface simple
					</p>{" "}
					<p className="small-text !leading-[20px]">
						d’utilisation disponible sur ordinateur,
						tablette & smartphone.
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc:
				"/assets/images/pictos/tableau-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Un tableau de bord
					</p>
					<p className="small-text !leading-[20px]">
						Pour visualiser rapidement toute votre activité.
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc:
				"/assets/images/pictos/locker2-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Une gestion facile de vos casiers 
					</p>
					<p className="small-text !leading-[20px]">
						Attribuez le casier que vous souhaitez à un
						objet donné.
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc: "/assets/images/pictos/roles-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Une gestion avancée des rôles utilisateurs
					</p>{" "}
					<p className="small-text !leading-[20px]">
						{" "}
						Ajoutez, modifiez ou supprimez les droits d'un
						collaborateur en toute simplicité.
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc: "/assets/images/pictos/clock-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Un historique
					</p>
					<p className="small-text !leading-[20px]">
						consultable à tout moment.
					</p>
				</>
			),
		},
	];

	return (
		<div className="relative w-full xl:max-w-[1320px]">
			<div className="mb-[48px] md:mb-[36px] md:flex md:flex-col md:items-center md:text-center lg:mb-[60px] xl:mb-[124px]">
				<p className="subtitle mb-[8px] text-[#3798D4]">
					API, CRM & PMS
				</p>
				<h2 className="font-semibold text-[#163F59] md:w-8/12 lg:w-[634px]">
					Comment nos casiers connectés sont-ils pilotés ?
				</h2>
			</div>

			<div className="mb-[96px] flex justify-end lg:max-w-[1320px] xl:mb-[160px]">
				<div className="relative -left-[32px] w-1/2">
					<img
						className="absolute top-[100px] right-0 max-w-[704px] xl:max-w-[1000px]"
						src="/assets/images/solutions/interface-desktop.webp"
						alt=""
						loading="lazy"
					/>
				</div>
				<div className="w-1/2">
					<h3 className="mb-[36px] text-[#163F59] md:w-[456px] lg:text-center xl:mb-[60px] xl:text-left">
						Gérez vos casiers à distance via notre interface
						de gestion
					</h3>
					<div className="z-[50] ">
						{manageInterface.map((item) => (
							<div
								key={item.id}
								className="mb-[16px] flex gap-[20px] md:items-center lg:mb-[36px]"
							>
								<div className="w-[60px]">
									<img
										src={item.imgSrc}
										alt="item"
										width={60}
										height={60}
									/>
								</div>
								<div className="w-[220px] md:w-[389px]">
									{item.text}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="flex justify-start gap-[76px]">
				<div className="w-[407px] lg:w-1/2">
					<div>
						<h3 className="mb-[36px] text-[#163F59]">
							Vous avez déjà votre logiciel de gestion ?
							Intégrez notre API !
						</h3>
					</div>
					<div className="mb-[20px] lg:mb-[36px]">
						<div className="flex flex-col gap-[16px]">
							<p className="lg:leading-[30px] xl:w-[500px]">
								Grâce à notre API ouverte et documentée,
								connectez votre armoire à clés à votre
								logiciel interne de gestion ou votre
								plateforme de réservation.
							</p>
							<p className="leading-[32px]">
								Notre API est compatible avec tous les DMS
								et CRM. Notre équipe technique vous
								accompagne dans l'intégration de la solution
								avec vos outils existants.
							</p>
						</div>
					</div>
					{/* <button className="text-start font-bold text-[#3798D4]">
						Télécharger notre documentation technique
					</button> */}
				</div>
				<div className="relative -left-[32px] w-1/2 " >
					<img
						className="xl:max-w-[750px] lg:max-w-[630px] lg:top-[150px]"
						src="/assets/images/solutions/API-desktop.png"
						alt="API Acses"
						loading="lazy"
						
					/>
				</div>
			</div>
		</div>
	);
};

export default HowToDriveDesktop;
