import React from "react";
import SitemapBlock from "./components/SitemapBlock";
import { Helmet } from "react-helmet";

const Sitemap = () => {
	const solutionsLinks = [
		{
			name: "Nos solutions connectées",
			path: "/solutions-connectees",
		},
		{
			name: "Comment ça marche ?",
			path: "/comment-ca-marche",
		},
		{
			name: "Personnaliser ma consigne",
			path: "/consigne-personnalisee",
		},
	];

	const areaSectorsLinks = [
		{
			name: "Hôtellerie",
			path: "/hotellerie",
		},
		{
			name: "AirBnB et locations",
			path: "/airbnb-locations-courte-duree",
		},
		{
			name: "Immobilier",
			path: "/immobilier",
		},
		{
			name: "Service aux entreprises",
			path: "/service-aux-entreprises",
		},
		{
			name: "Gestionnaires de flotte automobile",
			path: "/gestionnaires-flotte-automobile",
		},
		{
			name: "Concessionnaires automobiles",
			path: "/concessionaires-automobiles",
		},
	];

	const aboutLinks = [
		{
			name: "Notre histoire",
			path: "/notre-histoire",
		},
		{
			name: "Blog",
			path: "/blog",
		},
	];
	const helpLinks = [
		{
			name: "Contact",
			path: "/contact",
		},
	];

	return (
		<div className="relative flex justify-center px-[36px] pt-[116px] lg:px-[32px]">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Plan du site</title>
				<link
					rel="canonical"
					href="http://mysite.com/example"
				/>
			</Helmet>
			<div className="absolute top-0 -z-[10] w-full lg:-top-[150px]">
				<img
					className="w-full md:hidden"
					src="/assets/images/siteMap/background-mobile.svg"
					alt=""
					loading="lazy"
				/>
				<img
					className="hidden w-full md:flex lg:hidden"
					src="/assets/images/siteMap/background-tablette.svg"
					alt=""
					loading="lazy"
				/>
				<img
					className="hidden w-full lg:flex xl:hidden"
					src="/assets/images/siteMap/background-desktop.svg"
					alt=""
					loading="lazy"
				/>
				<img
					className="hidden w-full xl:flex"
					src="/assets/images/siteMap/background-desktop-large.svg"
					alt=""
					loading="lazy"
				/>
			</div>
			<div className="z-10 mb-[80px] lg:max-w-[960px] lg:pt-[200px] xl:max-w-[1320px]">
				<h1 className="mb-[80px] xl:text-[3.05rem]">
					Plan du site
				</h1>
				<div className="flex flex-col gap-[36px]">
					<SitemapBlock
						title="Nos solutions"
						linkContainer={solutionsLinks}
					/>
					<SitemapBlock
						title="Votre secteur d'activité"
						linkContainer={areaSectorsLinks}
					/>
					<SitemapBlock
						title="À propos d'Acses"
						linkContainer={aboutLinks}
					/>
					<SitemapBlock
						title="Aide"
						linkContainer={helpLinks}
					/>
				</div>
			</div>
		</div>
	);
};

export default Sitemap;
