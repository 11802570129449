import React from "react";
import Hero from "./components/Hero/Hero";
import PartnersBanner from "./components/PartnersBanner/PartnersBanner";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ContactInfos from "../ContactUs/components/ContactInfos/ContactInfos";
import HubspotContactForm from "pages/AskQuotation/components/HubspotContactForm";
const Index = () => {
  return (
    <>
      <div
        className="landingPageGradient flex justify-center pt-[36px] lg:pt-[60px] xl:pt-[72px]"
        style={{
          backgroundImage: "url('assets/images/askDemo/background.webp')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Réservez une démo gratuite !</title>
          <meta
            name="description"
            content="Vous gérez un grand nombre de clés ? Vous avez besoin d'une solution sécurisée & gérable à distance ? Découvrez nos casiers connectés ! 👉 Réservez une démo."
          />
        </Helmet>
        <section className="mx-[36px] w-full lg:mx-[32px] lg:grid lg:max-w-[1024px] lg:grid-cols-2 xl:mb-[40px] xl:max-w-[1320px]">
          <div className="mb-[36px] w-[120px] lg:mb-[48px] lg:w-[201px] xl:mb-[56px]">
            <Link to="/">
              <img
                className="w-full"
                src="/assets/images/logos/logo_acses_with_word_blue.svg"
                alt="logo Acses"
              />
            </Link>
            <div className="hidden lg:mt-[48px] lg:block">
              <Hero />
            </div>
          </div>
          <div className="mb-[40px] lg:row-start-2 lg:hidden">
            <Hero />
          </div>
          <div className="mb-[96px] min-h-[690px] lg:min-h-[848px] lg:order-3 lg:col-span-1 lg:min-h-[723px] xl:order-2">
            <HubspotContactForm
              region="eu1"
              portalId="26965274"
              formId="f1d88562-6366-4e3d-a2d3-cf8ad314939f"
            />
          </div>
          <div className="lg:row-start-3 lg:-mt-[190px]">
            <ContactInfos />
          </div>
        </section>
      </div>
      <div className="flex justify-center bg-[#F6F8FC] py-[40px] px-[36px]">
        <PartnersBanner />
      </div>
    </>
  );
};

export default Index;
