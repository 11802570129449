import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const pHero = [
	{
		id: uuidv4(),
		text: (
			<p className="mb-[16px]">
				Vous souhaitez optimiser la gestion de votre flotte
				automobile ? Gagner du temps au quotidien ?
			</p>
		),
	},
	{
		id: uuidv4(),
		text: (
			<p className="mb-[16px]">
				La gestion d’une flotte de véhicules est complexe et
				peut rapidement s’avérer coûteuse et chronophage.
				Au-delà de ces aspects, une gestion fluide constitue
				également une source de satisfaction clients et de
				sérénité pour vos collaborateurs.
			</p>
		),
	},
	{
		id: uuidv4(),
		text: (
			<p>
				Suivez en temps réel et en toute simplicité les
				entrées et sorties de vos véhicules. Grâce à notre
				logiciel Acses Manager, tracez les mouvements de
				clés et les transferts de responsabilité.
			</p>
		),
	},
];

const heroImgs = {
	mobile:
		"/assets/images/activitySector/flotte-auto/header.jpg",
	tablette:
		"/assets/images/activitySector/flotte-auto/header.jpg",
	desktop:
		"/assets/images/activitySector/flotte-auto/header.jpg",
	desktopLarge:
		"/assets/images/activitySector/flotte-auto/header.jpg",
};

const grayBlockImg = {
	mobile:
		"/assets/images/activitySector/flotte-auto/mozaique-mobile-tablette.webp",
	tablette:
		"/assets/images/activitySector/flotte-auto/mozaique-mobile-tablette.webp",
	desktop:
		"/assets/images/activitySector/flotte-auto/mozaique-desktop.webp",
	desktopLarge:
		"/assets/images/activitySector/flotte-auto/mozaique-desktop.webp",
};

const grayBlockP = {
	pBold:
		" Les clés de vos véhicules circulent librement parmi vos clients et vos collaborateurs et génèrent trop souvent des erreurs ou des pertes. Les coûts supplémentaires ainsi engendrés peuvent rapidement devenir problématiques pour une entreprise.",
	pTag: [
		<p>
			Vous manquez de visibilité sur les échanges de clés ?
			Ne courez plus après !{" "}
			<strong>Économisez sur les coûts opérationnels</strong>  et gagnez du temps avec notre{" "}
			<strong>
				logiciel de gestion à distance Acses Manager.
			</strong>{" "}
			Contrôlez à distance les dépôts et les retraits :{" "}
			<strong>
				tracez simplement l’ensemble des mouvements de vos
				clés
			</strong>
			. Vous savez à tout moment qui détient les clés !
		</p>,
	],
};

const managementContent = [
	{
		id: uuidv4(),
		imgAlt:"optimisation de la gestion d'une flotte automobile",
		imgSrcMobile:
			"/assets/images/activitySector/flotte-auto/two-phones-mobile-tablette.png",
		imgSrcTablette:
			"/assets/images/activitySector/flotte-auto/two-phones-mobile-tablette.png",
		imgSrcDesktop:
			"/assets/images/activitySector/flotte-auto/two-phones-desktop-large.jpg",
		imgSrcDesktopLarge:
			"/assets/images/activitySector/flotte-auto/two-phones-desktop-large.jpg",
		title: "Une gestion quotidienne facilitée",
		textContent: (
			<p>
				Grâce à notre logiciel disponible sur <strong>desktop</strong>,
				<strong> tablette </strong> et <strong>mobile</strong>, chef de centre et collaborateurs
				peuvent facilement prendre la main sur les
				réservations et les annulations 24h/24, 7j/7.
			</p>
		),
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
	{
		id: uuidv4(),
		imgAlt:"armoire à clés en métal anti-effraction",
		imgSrcMobile:
			"/assets/images/activitySector/flotte-auto/phone-code-mobile-tablette.png",
		imgSrcTablette:
			"/assets/images/activitySector/flotte-auto/phone-code-mobile-tablette.png",
		imgSrcDesktop:
			"/assets/images/activitySector/flotte-auto/phone-code-desktop-large.jpg",
		imgSrcDesktopLarge:
			"/assets/images/activitySector/flotte-auto/phone-code-desktop-large.jpg",
		title: "Une traçabilité de vos clés",
		textContent: (
			<>
				<p className="mb-[10px]">
					Notre système logiciel & consigne connectée vous
					permettent{" "}
					<Link to="/comment-ca-marche">
						<span className="text-[#3798D4] underline">
							{" "}
							de tracer les clés{" "}
						</span>
					</Link>{" "}
					en temps réel.
				</p>
				<p>
					Rationalisez le suivi de vos clés pour fluidifier
					les échanges et maîtriser votre planning !
				</p>
			</>
		),
		reverse: true,
		widthBlock: "md:w-[452px]",
	},
	{
		id: uuidv4(),
		imgAlt:"logiciel acses manager pour gérer vos clés à distance",
		imgSrcMobile:
			"/assets/images/activitySector/flotte-auto/api-locker-mobile-tablette.png",
		imgSrcTablette:
			"/assets/images/activitySector/flotte-auto/api-locker-mobile-tablette.png",
		imgSrcDesktop:
			"/assets/images/activitySector/flotte-auto/api-locker-desktop-large.jpg",
		imgSrcDesktopLarge:
			"/assets/images/activitySector/flotte-auto/api-locker-desktop-large.jpg",
		title: "Une intégration logicielle à votre système",
		textContent: (
			<>
				<p className="mb-[10px]">
					Grâce à notre API, connectez facilement vos
					<Link to="/solutions-connectees">
						<span className="text-[#3798D4] underline">
							{" "}
							casiers connectés
						</span>
					</Link>{" "}
					à votre système de réservation.
				</p>
				<p className="mb-[10px]">
					Un chef de projet technique vous accompagne dans
					l’implémentation de notre solution avec vos outils
					existants.
				</p>
			</>
		),
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
];

export {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
};
