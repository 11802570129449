import React from "react";

const InterfaceLockers = () => {
	return (
		<div className="w-full">
			<div className="w-full md:hidden">
				<img
					className="w-full"
					src="/assets/images/howItWorks/interface-locker-mobile.png"
					alt="5 à 150 casiers connectés et sécurisés"
					width={360}
					height={169}
					loading="lazy"
				/>
			</div>
			<div className="hidden w-full md:flex lg:hidden">
				<img
					className="w-full"
					src="/assets/images/howItWorks/interface-locker-tablette.webp"
					alt="5 à 150 casiers connectés et sécurisés"
					width={696}
					height={322}
					loading="lazy"
				/>
			</div>
			<div className="hidden justify-center lg:flex">
				<img
					src="/assets/images/howItWorks/interface-locker-desktop.webp"
					alt="5 à 150 casiers connectés et sécurisés"
					width={869}
					height={402}
					loading="lazy"
				/>
			</div>
		</div>
	);
};

export default InterfaceLockers;
