import React from "react";
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import allRoutes from "./allRoutes";
import Layout from "../layout";
import TagManager from 'react-gtm-module'
import ScrollToTop from "utils/ScrollToTop";
import CookiesBanner from "sharedComponents/CookiesBanner/CookiesBanner";


function Router(props) {
	return (
		<BrowserRouter>
			<CookiesBanner />
		<ScrollToTop />
			<Routes>
				{allRoutes.map((route) => (
					<Route
						key={route.path}
						exact
						path={route.path}
						element={
							<Layout name={route.layoutName}>
								{route.component}
							</Layout>
						}
					/>
				))}
				<Route path="*" element={<Navigate to="/404" replace />}/>
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
