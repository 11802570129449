import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const pHero = [
	{
		id: uuidv4(),
		text: (
			<p className="mb-[16px]">
				Vous passez beaucoup de temps à gérer vos clés ?
				Vous devez collaborer avec de nombreux prestataires
				?
			</p>
		),
	},
	{
		id: uuidv4(),
		text: (
			<>
				<p>
					Nos consignes intelligentes vous permettent
					d'optimiser votre temps et d'améliorer votre
					productivité en toute simplicité. Grâce à notre
					logiciel Acses Manager, gérez à distance vos clés,
					vos collaborateurs et vos prestataires externes.
				</p>
			</>
		),
	},
];

const heroImgs = {
	mobile:
		"/assets/images/activitySector/immobilier/header.jpg",
	tablette:
		"/assets/images/activitySector/immobilier/header.jpg",
	desktop:
		"/assets/images/activitySector/immobilier/header.jpg",
	desktopLarge:
		"/assets/images/activitySector/immobilier/header.jpg",
};

const grayBlockImg = {
	mobile:
		"/assets/images/activitySector/immobilier/mozaique-mobile-tablette.webp",
	tablette:
		"/assets/images/activitySector/immobilier/mozaique-mobile-tablette.webp",
	desktop:
		"/assets/images/activitySector/immobilier/mozaique-desktop.webp",
	desktopLarge:
		"/assets/images/activitySector/immobilier/mozaique-desktop.webp",
};

const grayBlockP = {
	pBold:
		"Vous gérez un parc immobilier ? Un syndic de copropriété ? Les clés de vos établissements circulent au sein de vos équipes mais aussi parmi vos prestataires externes et peuvent générer des erreurs. Plus le nombre d’intervenants est important, plus leur gestion est complexe et chronophage.",
	pTag: [
		<p>
			Installés en agence ou sur le terrain,{" "}
			<Link to="/solutions-connectees">
				<span className="text-[#3798D4] hover:text-[#3184B7] underline">nos casiers sécurisés </span>
			</Link>{" "}
			fluidifient les échanges de clés au sein de votre
			organisation. Grâce à notre logiciel Acses Manager
			vous savez à tout moment qui détient les clés.
			Plombier, electricien, femme de ménage … restez au
			courant du passage de vos prestataires.
		</p>,
	],
};

const managementContent = [
	{
		id: uuidv4(),
		imgAlt: "gestion optimisée des clés pour l'immobilier",
		imgSrcMobile:
			"/assets/images/activitySector/immobilier/self-check-mobile-tablette.webp",
		imgSrcTablette:
			"/assets/images/activitySector/immobilier/self-check-mobile-tablette.webp",
		imgSrcDesktop:
			"/assets/images/activitySector/immobilier/self-check-desktop-large.webp",
		imgSrcDesktopLarge:
			"/assets/images/activitySector/immobilier/self-check-desktop-large.webp",
		title:
			"Gestion à distance de vos clés, collaborateurs & prestataires",
		textContent: [
			<p>
				Grâce à notre logiciel Acses Manager, contrôlez à
				distance les accès à vos <strong>casiers connectés</strong>. <strong>Modifiez
				les accès</strong>  de vos collaborateurs et prestataires où
				que vous soyez via un <strong>ordinateur</strong>, une <strong>tablette</strong> ou
				encore un <strong>smartphone</strong>.
			</p>,
		
		],
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
	{
		id: uuidv4(),
		imgAlt:
			"casiers connectés pour gestion à distance des clés",
		imgSrcMobile:
			"/assets/images/activitySector/immobilier/notif-mobile-tablette.webp",
		imgSrcTablette:
			"/assets/images/activitySector/immobilier/notif-mobile-tablette.webp",
		imgSrcDesktop:
			"/assets/images/activitySector/immobilier/notif-desktop-large.jpg",
		imgSrcDesktopLarge:
			"/assets/images/activitySector/immobilier/notif-desktop-large.jpg",
		title: "Une traçabilité de vos clés",
		textContent: [
			<p>
				<strong>
					Contrôlez les dépôts et les retraits de clés en
					temps réel
				</strong>{" "}
				dans notre logiciel Acses Manager. D’un seul coup
				d’oeil, visualisez l’ensemble des mouvements actuels
				et passés de vos clés. Vous savez qui détient les
				clés et depuis combien de temps.
			</p>,
		],
		reverse: true,
		widthBlock: "md:w-[452px]",
	},
	{
		id: uuidv4(),
		imgAlt: "interface de gestion acses manager",
		imgSrcMobile:
			"/assets/images/activitySector/immobilier/key-check-in-mobile-tablette.webp",
		imgSrcTablette:
			"/assets/images/activitySector/immobilier/key-check-in-mobile-tablette.webp",
		imgSrcDesktop:
			"/assets/images/activitySector/immobilier/key-check-in-desktop-large.jpg",
		imgSrcDesktopLarge:
			"/assets/images/activitySector/immobilier/key-check-in-desktop-large.jpg",
		title: "Gestion optimisée des clés",
		textContent: [
			<p className="mb-[10px]">
				Le rangement de vos clés n’a jamais été aussi simple
				! Ne perdez plus de temps, retrouvez facilement vos
				clés grâce à nos casiers connectés à notre interface
				de gestion.
			</p>,
		],
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
];

export {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
};
