const locale = {
    H2:"<0>Sectors of activity</0><1>A tool designed for your business</1>",
    SPAN_BTN:"Learn more",
    ACTIVITY_TITLE_1:"Hotel",
    ACTIVITY_BODY_1:"Offer more flexibility to your customers and your teams! Contactless check-in and check-out 24/7.",
    ACTIVITY_TITLE_2:"Airbnb & short-term rental",
    ACTIVITY_BODY_2:"Simplify your guests' stay by giving them access to an automated key collection experience.",
    ACTIVITY_TITLE_3:"Real estate",
    ACTIVITY_BODY_3:"Optimize the management of your real estate portfolio remotely.",
    ACTIVITY_TITLE_4:"Business Services",
    ACTIVITY_BODY_4:"Control access to your premises and easily trace transfers of responsibility.",
    ACTIVITY_TITLE_5:"Car dealers",
    ACTIVITY_BODY_5:"Differentiate yourself by offering 24/7 service to your customers.",
    ACTIVITY_TITLE_6:"Fleet Managers",
    ACTIVITY_BODY_6:"Manage your car fleet remotely: 24/7 reservations, key traceability and exchange tracking.",
    
    }
    
    export default locale