import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../Button";
import "./DesktopNav.scss";
import clsx from "clsx";
const DesktopNav = () => {
	const navigate = useNavigate();
	const [toggleSolutionMenu, settoggleSolutionMenu] =
		useState(false);
	const [toggleActivitiesMenu, settoggleActivitiesMenu] =
		useState(false);
	const [toggleAboutMenu, settoggleAboutMenu] =
		useState(false);
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		scrollOn();
		window.addEventListener("scroll", scrollOn);
	});

	const scrollOn = () => {
		if (window.scrollY > 0) {
			return setScroll(true);
		}
		return setScroll(false);
	};

	return (
		<div className={clsx("fixed top-0 z-[90] flex w-full justify-center px-[36px] pt-[27px] lg:px-[32px]", scroll && "bg-white pb-12")}>
			<div className="flex w-full flex-col gap-y-[36px] lg:max-w-[960px]  xl:max-w-[1320px]">
				<div className="flex items-center justify-end gap-[36px]">
					<button
						onClick={() => navigate("/")}
						className="pointer-events-auto z-[10] mr-auto h-[54px] xl:hidden"
					>
						<img
							className="h-full"
							src="/assets/images/logos/logo_acses_with_word_blue.svg"
							alt="logo Acses"
							width={181.5}
							height={54}
							
						/>
					</button>
					{/* <div className="flex gap-[8px] items-center">
						<img
							src="/assets/images/pictos/search.svg"
							alt=""
						/>
						<p className="text-[#163F59] text-[16px]">
							Rechercher
						</p>
					</div> */}
          {/* <div className="flex items-center gap-[8px]">
						<img
							src="/assets/images/pictos/help-center.png"
							alt=""
						/>
						<p className="text-[#163F59] text-[16px]">
							Centre d'aide
						</p>
					</div> */}
					<div className="xl:hidden">
						<a
							href="https://bit.ly/contact-acses"
							className="flex items-center justify-center primary-btn font-bold"
							rel="noopener"
						>
							Contactez-nous
						</a>
					</div>
				</div>
				<div className="flex items-center justify-between">
					<button
						name="logo"
						id="logo"
						aria-label="logo"
						onClick={() => navigate("/")}
					>
						<div className="hidden h-[54px] xl:flex">
							<img
								className="h-full"
								src="/assets/images/logos/logo_acses_with_word_blue.svg"
								alt=""
								width={181.5}
								height={54}
							/>
						</div>
					</button>

					<ul
						onMouseLeave={(e) => {
							settoggleAboutMenu(false);
							settoggleActivitiesMenu(false);
							settoggleSolutionMenu(false);
						}}
						className="relative flex gap-[36px]"
					>
						<li className="flex items-center">
							<details
								open={toggleSolutionMenu}
								onMouseEnter={(e) => {
									settoggleSolutionMenu(true);
									settoggleAboutMenu(false);
									settoggleActivitiesMenu(false);
								}}
							>
								<summary className="flex gap-[12px] text-[16px] font-semibold text-[#163F59] cursor-pointer">
									Nos solutions
									<div
										style={{
											transformOrigine: "center",
											transform:
												toggleSolutionMenu &&
												"rotate(180deg)",
										}}
									>
										<img
											src="/assets/images/pictos/arrow-down-dark.svg"
											alt="Arrow Down"
											width={12}
											height={32}
										/>
									</div>
								</summary>
								<div className="shadowBoxCarded desktopContainerLink absolute top-[50px] z-[50] flex flex-col gap-[23px] !rounded-[8px] bg-white px-[36px] py-[24px]">
									<Link to="/comment-ca-marche">
										<div className="linkEffect flex items-center gap-[16px]">
											<div className="w-[25px]">
												<img
													className="w-full"
													src="/assets/images/pictos/how-it-work-desktop-nav.svg"
													alt=""
													width={19}
													height={24}
												/>
											</div>
											<p className="text-[16px] font-semibold">
												Comment ça marche ?
											</p>
										</div>
									</Link>
									<div>
										<Link to="/solutions-connectees">
											<div className="linkEffect text-[16px] font-semibold">
											<div className="flex items-center gap-[16px]">
												<div className="w-[25px]">
													<img
														className="w-full"
														src="/assets/images/pictos/solution-desktop-nav.svg"
														alt=""
														width={21}
														height={24}
														loading="lazy"
													/>
												</div>
												<p className="text-[16px] font-semibold">
													Nos solutions connectées
												</p>
											</div>
											</div>
										</Link>
									</div>
								</div>
							</details>
						</li>
						<li className="flex items-center">
							<details
								open={toggleActivitiesMenu}
								onMouseEnter={(e) => {
									settoggleActivitiesMenu(true);
									settoggleAboutMenu(false);
									settoggleSolutionMenu(false);
								}}
							>
								<summary className="flex gap-[12px] text-[16px] font-semibold text-[#163F59] cursor-pointer ">
									Votre secteur d'activité
									<div
										style={{
											transformOrigine: "center",
											transform:
												toggleActivitiesMenu &&
												"rotate(180deg)",
										}}
									>
										<img
											src="/assets/images/pictos/arrow-down-dark.svg"
											alt=""
											width={12}
											height={32}
											
										/>
									</div>
								</summary>
								<div className="shadowBoxCarded desktopContainerLinkActivity absolute left-0 top-[50px] z-[5000] flex !rounded-[8px] bg-white p-[36px]">
									<div className="w-full max-w-[320px] border-r-[1.9px] border-[#F0F5FA] pr-[36px]">
										<div className=" mb-[24px] cursor-pointer">
											<Link to="/blog">
											<p className="linkEffect text-[16px] font-semibold">
												Études de cas
											</p>
											<p className="small-text !leading-[18px]">
											Découvrez les success stories de nos clients
											</p>
											</Link>
										</div>
										<div
											style={{
												backgroundImage:
													"url('/assets/images/navbar/pexels-andrea.png')",
											}}
											className="scaleOnHover h-[200px] w-full max-w-[320px] rounded-[8px] bg-light-blue"
										></div>
									</div>
									<hr />
									<div className="flex flex-col justify-center gap-[16px] pl-[36px]">
										<div>
											<Link to="/hotellerie">
												<div className="linkEffect flex items-center gap-[16px]">
													<div className="w-[25px]">
														<img
															className="w-full"
															src="/assets/images/pictos/hotellerie-desktop-nav.svg"
															alt=""
															width={18}
															height={24}
														/>
													</div>
													<p className="text-[16px] font-semibold">
														Hôtellerie
													</p>
												</div>
											</Link>
										</div>
										<div>
											<Link to="/immobilier">
												<div className="linkEffect flex items-center gap-[16px]">
													<div className="w-[25px]">
														<img
															className="w-full"
															src="/assets/images/pictos/immobilier-desktop-nav.svg"
															alt=""
															width={22}
															height={25}
														/>
													</div>
													<p className="text-[16px] font-semibold">
														Immobilier
													</p>
												</div>
											</Link>
										</div>
										<div>
											<Link to="/airbnb-locations-courte-duree">
												<div className="linkEffect flex items-center gap-[16px]">
													<div className="w-[25px]">
														<img
															className="w-full"
															src="/assets/images/pictos/rbnb-desktop-nav.svg"
															alt=""
															width={22}
															height={24}
														/>
													</div>
													<p className="text-[16px] font-semibold">
														Airbnb & locations courte durée
													</p>
												</div>
											</Link>
										</div>
										<div>
											<Link to="/service-aux-entreprises">
												<div className="linkEffect flex items-center gap-[16px]">
													<div className="w-[25px]">
														<img
															className="w-full"
															src="/assets/images/pictos/services-desktop-nav.svg"
															alt=""
															width={25}
															height={24}
															loading="lazy"
														/>
													</div>
													<p className="text-[16px] font-semibold">
														Service aux entreprises
													</p>
												</div>
											</Link>
										</div>
										<div>
											<Link to="/gestionnaires-flotte-automobile">
												<div className="linkEffect flex items-center gap-[16px]">
													<div className="w-[25px]">
														<img
															className="w-full"
															src="/assets/images/pictos/auto-gestion-desktop-nav.svg"
															alt=""
															width={27}
															height={24}
															loading="lazy"
														/>
													</div>
													<p className="text-[16px] font-semibold">
														Gestionnaires de flottes
														automobile
													</p>
												</div>
											</Link>
										</div>
										<div>
											<Link to="/concessionaires-automobiles">
												<div className="linkEffect flex items-center gap-[16px]">
													<div className="w-[25px]">
														<img
															className="w-full"
															src="/assets/images/pictos/auto-gestion-desktop-nav.svg"
															alt=""
															width={27}
															height={24}
															
														/>
													</div>
													<p className="text-[16px] font-semibold">
														Concessionaires automobiles
													</p>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</details>
						</li>
						<li className="relative flex items-center">
							<details
								open={toggleAboutMenu}
								onMouseEnter={(e) => {
									settoggleAboutMenu(true);
									settoggleActivitiesMenu(false);
									settoggleSolutionMenu(false);
								}}
							>
								<summary className="flex gap-[12px] text-[16px] font-semibold text-[#163F59] cursor-pointer">
									À propos
									<div
										style={{
											transformOrigine: "center",
											transform:
												toggleAboutMenu &&
												"rotate(180deg)",
										}}
									>
										<img
											src="/assets/images/pictos/arrow-down-dark.svg"
											alt=""
											width={12}
											height={32}
											loading="lazy"
										/>
									</div>
								</summary>
								<div className="shadowBoxCarded desktopContainerLinkAbout absolute -left-[40px] top-[50px] z-10 flex w-[230px] flex-col gap-[16px] !rounded-[8px] bg-white px-[36px] py-[24px]">
									<div>
										<Link to="/notre-histoire">
											<div className="linkEffect flex items-center gap-[16px]">
												<div className="flex w-[25px] justify-center">
													<img
														className="mx-[5px] w-full"
														src="/assets/images/pictos/story-desktop-nav.svg"
														alt=""
														width={18}
														height={24}
													
													/>
												</div>
												<p className="text-[16px] font-semibold">
													Notre histoire
												</p>
											</div>
										</Link>
									</div>
									<div>
										<Link to="/blog">
											<div>
												<div className="linkEffect flex items-center gap-[16px]">
													<div className="mr-[10px] w-[15px]">
														<img
															className="mx-[5px] w-full"
															src="/assets/images/pictos/blog-desktop-nav.svg"
															alt=""
															width={15}
															height={25}
															
														/>
													</div>
													<p className="text-[16px] font-semibold">
														Blog
													</p>
												</div>
											</div>
										</Link>
									</div>
									{/* <div>
										<Link to="/">
											<div className="flex items-center gap-[16px]">
												<div className="flex w-[15px] mr-[10px]">
													<img
														className="mx-[5px] w-full"
														src="/assets/images/pictos/presse-desktop-nav.svg"
														alt=""
													/>
												</div>
												<p className="font-semibold text-[16px] linkEffect">
													Presse
												</p>
											</div>
										</Link>
									</div> */}
                  {/* <div>
										<Link to="/">
											<div className="flex items-center gap-[16px]">
												<div className="w-[20px] mr-[5px]">
													<img
														className="mx-[5px] w-full"
														src="/assets/images/pictos/recrutement-desktop-nav.svg"
														alt=""
													/>
												</div>
												<p className="font-semibold text-[16px] linkEffect">
													Recrutement
												</p>
											</div>
										</Link>
									</div> */}
									<div>
										<Link to="/contact">
											<div className="linkEffect flex items-center gap-[16px]">
												<div className="mr-[5px] w-[20px]">
													<img
														className="mx-[5px] w-full"
														src="/assets/images/pictos/headphones-desktop-nav.svg"
														alt=""
														width={21}
														height={25}
													/>
												</div>

												<p className="text-[16px] font-semibold">
													Contact
												</p>
											</div>
										</Link>
									</div>
								</div>
							</details>
						</li>
						<li>
							<Link
								to={"/consigne-personnalisee"}
								className="linkEffect text-[16px] font-semibold text-[#163F59]"
							>
								Personnaliser ma consigne
							</Link>
						</li>
					</ul>
					<div className="hidden xl:flex">
						<a
							href="https://bit.ly/contact-acses"
							className="flex items-center justify-center primary-btn font-bold"
							rel="noopener"
						>
							Contactez-nous
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DesktopNav;
