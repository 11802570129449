import React from "react";

const PoliticPrivacy = () => {
  const data = [
    {
      title: "Introduction",
      textContent: (
        <p className="mb-[12px]">
          La présente Politique des cookies s'applique au site web et aux
          services d'ACSES. Notre site utilise des cookies et d'autres
          technologies de suivi similaires pour améliorer l'expérience
          utilisateur, comprendre les préférences des visiteurs et personnaliser
          le contenu et les publicités. En utilisant notre site, vous acceptez
          notre utilisation des cookies conformément à cette politique.
        </p>
      ),
    },
    {
      title: "Qu'est-ce qu'un cookie ?",
      textContent: (
        <p className="mb-[12px]">
          Un cookie est un petit fichier texte qui est stocké sur votre
          ordinateur, smartphone ou autre appareil lorsque vous visitez un site
          web. Les cookies sont utilisés pour conserver des informations sur
          votre navigation, vos préférences et d'autres données, afin de
          faciliter la navigation et de rendre le site plus personnalisé et
          pertinent.
        </p>
      ),
    },
    {
      title: "Types de cookies",
      textContent: (
        <>
          {" "}
          <p className="mb-[12px]">
            <strong>Cookies de session :</strong> Ces cookies sont temporaires
            et sont supprimés lorsque vous fermez votre navigateur. Ils sont
            utilisés pour stocker des informations sur la navigation en cours.
          </p>
          <p className="mb-[12px]">
            <strong>Cookies persistants:</strong> Ces cookies restent sur votre
            appareil pour une période plus longue et sont utilisés pour
            conserver des informations entre différentes sessions de navigation.
          </p>
          <p className="mb-[12px]">
            <strong>Cookies tiers :</strong> Ces cookies sont créés par des
            entités autres que le site que vous visitez, comme les annonceurs ou
            les fournisseurs de services analytiques. Ils sont utilisés pour
            suivre votre comportement en ligne et personnaliser les publicités.
          </p>
        </>
      ),
    },
    {
      title: "Comment nous utilisons les cookies",
      textContent: (
        <>
          {" "}
          <p className="mb-[12px]">
            <strong>Cookies fonctionnels :</strong> Ces cookies sont nécessaires
            au fonctionnement de notre site et pour vous fournir les services
            que vous demandez. Ils incluent, par exemple, des cookies qui vous
            permettent de vous connecter à des zones sécurisées de notre site.
          </p>
          <p className="mb-[12px]">
            <strong>Cookies analytiques :</strong> Ces cookies nous permettent
            de recueillir des informations sur la manière dont les visiteurs
            utilisent notre site, notamment le nombre de visiteurs, les pages
            visitées et la durée des visites. Ces informations nous aident à
            améliorer notre site et à offrir une meilleure expérience
            utilisateur.
          </p>
          <p className="mb-[12px]">
            <strong>Cookies de ciblage :</strong> Cookies de ciblage ou de
            publicité : Ces cookies sont utilisés pour afficher des publicités
            pertinentes pour vous et vos intérêts. Ils sont également utilisés
            pour limiter le nombre de fois que vous voyez une publicité et pour
            mesurer l'efficacité des campagnes publicitaires.
          </p>
        </>
      ),
    },
    {
      title: "Gérer les cookies ",
      textContent: (
        <p className="mb-[12px]">
          Vous pouvez gérer vos préférences de cookies en modifiant les
          paramètres de votre navigateur. La plupart des navigateurs vous
          permettent de bloquer ou de supprimer les cookies, mais cela peut
          affecter la fonctionnalité du site et votre expérience utilisateur.
        </p>
      ),
    },
    {
      title: "Modifications de la Politique des cookies ",
      textContent: (
        <p className="mb-[12px]">
          Nous nous réservons le droit de modifier cette Politique des cookies à
          tout moment. Toute modification entrera en vigueur immédiatement après
          la publication de la politique mise à jour sur notre site. Nous vous
          encourageons à consulter régulièrement cette page pour vous informer
          de toute mise à jour.
        </p>
      ),
    },
    {
      title: "Contactez-nous",
      textContent: (
        <p className="mb-[12px]">
          Si vous avez des questions concernant cette Politique des cookies ou
          notre utilisation des cookies, veuillez nous contacter à l'adresse
          e-mail suivante : <strong>support@acses.io</strong> 
        </p>
      ),
    },
  ];
  return (
    <div className="flex justify-center">
      <div className="mx-[36px] flex flex-col gap-[36px] py-[150px] lg:pt-[250px] max-w-[936px] lg:max-w-[1320px]">
        {data.map((block) => (
          <div>
            <h2>{block.title}</h2>
            {block.textContent}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PoliticPrivacy;
