import React from "react";
import "./App.css";
import Router from "./routeConfig/Router";
import TagManager from "react-gtm-module";
import { CookiesContext } from "CookiesContext";
import { useContext } from "react";
import CookiesBanner from "./sharedComponents/CookiesBanner/CookiesBanner";
import CookieTag from "sharedComponents/CookieTag";
import CookieSettings from "sharedComponents/CookieSettings";

function App() {
  const cookiesChoice = useContext(CookiesContext);
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
