const locale = {
H2:"<0>Secteurs d’activité</0><1>Un outil pensé pour votre métier</1>",
SPAN_BTN:"En savoir plus",
ACTIVITY_TITLE_1:"Hôtellerie",
ACTIVITY_BODY_1:"Offrez plus de flexibilité à vos clients et à vos équipes ! Check-in et check-out sans contact 24/24h et 7/7J.",
ACTIVITY_TITLE_2:"Airbnb & location courte durée",
ACTIVITY_BODY_2:"Simplifiez le séjour de vos clients en leur donnant accès à une expérience automatisée de récupération de clés.",
ACTIVITY_TITLE_3:"Immobilier",
ACTIVITY_BODY_3:"Optimisez à distance la gestion de votre parc immobilier.",
ACTIVITY_TITLE_4:"Service aux entreprises",
ACTIVITY_BODY_4:"Contrôlez les accès à vos locaux et tracez facilement les transferts de responsabilité.",
ACTIVITY_TITLE_5:"Concessionnaires automobiles",
ACTIVITY_BODY_5:"Différenciez-vous en proposant un service 24/7 à vos clients.",
ACTIVITY_TITLE_6:"Gestionnaires de flotte automobile",
ACTIVITY_BODY_6:"Pilotez à distance votre flotte automobile : réservations 24/7, traçabilité des clés et suivi des échanges.",

}

export default locale