import React from "react";

const Genesis = () => {
  return (
    <>
      <div className="md:hidden">
        <h2 className="mb-[16px]">À l’origine de cette aventure</h2>
        <div>
          <p className="mb-[16px] font-bold text-[#163F59]">
            Cédric Schwartzenberger a commencé sa carrière en tant que
            consultant chez Capgemini, et s'est rapidement tourné vers
            l'entrepreneuriat.
          </p>
          <p className="mb-[16px] font-medium text-[#163F59]">
            {" "}
            Il constitue alors une équipe d’ingénieurs issus de la FrenchTech
            pour l'accompagner dans son projet. C'est ainsi qu'en 2016, il crée
            la start-up Monkey Locky, un service d’échange de clés, fondé sur la
            location de casiers connectés. Le besoin est bien présent, le succès
            se fait sentir.
          </p>
          <p className="mb-[16px] font-medium text-[#163F59]">
            Cédric Schwartzenberger est attaché à des principes forts tels que
            la confiance, l’autonomie, le made in France et le respect de ses
            différents partenaires.
          </p>
          <p className="mb-[16px] font-medium text-[#163F59]">
            La conjoncture et sa pugnacité à avoir un regard et un contrôle
            permanent sur toutes les étapes liées à la solution l’amène à passer
            un cran supérieur, soutenu par ses collaborateurs.
          </p>
          <p className="mb-[16px] font-medium text-[#163F59]">
            Et c’est en 2020, que Cédric lance la marque ACSES en tant que
            fournisseur de solutions de casiers connectées sur mesure, pour les
            professionnels BtoB. Diplômé d’un MBA en gestion d’entreprise,
            Cédric est un entrepreneur passionné et déterminé, dont la vision
            est d’aider les entreprises à digitaliser leur parcours client pour
            offrir une expérience personnalisée et automatisée.
          </p>
          <p className="mb-[16px] font-medium text-[#163F59]">
            Il est animé par l'innovation et l'amélioration constante de ses
            produits, tout en ayant à cœur de les fabriquer en France.
          </p>
        </div>
      </div>

      <div className="tinyShadow relative hidden bg-white md:relative md:-top-[200px] md:block md:p-[36px] lg:min-h-[472px] lg:max-w-[1320px] lg:overflow-hidden lg:pl-[193px] xl:min-h-[596px] xl:p-[80px] xl:pl-[563px]">
        <h2 className="mb-[16px] ">A l’origine de cette aventure</h2>
        <div className="absolute left-0 bottom-0 hidden lg:flex xl:-left-[1px] xl:-bottom-[2px]">
          <img
            className="xl:hidden"
            src="/assets/images/ourStory/cedric.webp"
            alt="Cédric Schwartzenberger fondateur d'Acses"
            loading="lazy"
          />
          <img
            className="hidden xl:flex"
            src="/assets/images/ourStory/cedric-desktop-large.webp"
            alt="Cédric Schwartzenberger fondateur d'Acses"
          />
        </div>
        <div>
          <p className="mb-[16px] font-bold">
            Cédric Schwartzenberger a commencé sa carrière en tant que
            consultant chez Capgemini, et s'est rapidement tourné vers
            l'entrepreneuriat.
          </p>
          <p className="mb-[16px] font-medium">
            {" "}
            Il constitue alors une équipe d’ingénieurs issus de la FrenchTech
            pour l'accompagner dans son projet. C'est ainsi qu'en 2016, il crée
            la start-up Monkey Locky, un service d’échange de clés, fondé sur la
            location de casiers connectés. Le besoin est bien présent, le succès
            se fait sentir.
          </p>
          <p className="mb-[16px] font-medium">
            Cédric Schwartzenberger est attaché à des principes forts tels que
            la confiance, l’autonomie, le made in France et le respect de ses
            différents partenaires.
          </p>
          <p className="mb-[16px] font-medium">
            La conjoncture et sa pugnacité à avoir un regard et un contrôle
            permanent sur toutes les étapes liées à la solution l’amène à passer
            un cran supérieur, soutenu par ses collaborateurs.
          </p>
          <p className="mb-[16px] font-medium">
            Et c’est en 2020, que Cédric lance la marque ACSES en tant que
            fournisseur de solutions de casiers connectées sur mesure, pour les
            professionnels BtoB. Diplômé d’un MBA en gestion d’entreprise,
            Cédric est un entrepreneur passionné et déterminé, dont la vision
            est d’aider les entreprises à digitaliser leur parcours client pour
            offrir une expérience personnalisée et automatisée.
          </p>
          <p className="mb-[16px] font-medium">
            Il est animé par l'innovation et l'amélioration constante de ses
            produits, tout en ayant à cœur de les fabriquer en France.
          </p>
        </div>
      </div>
    </>
  );
};

export default Genesis;
