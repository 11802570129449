import React from "react";

const Links = () => {
	return (
		<div className="sticky hidden w-[396px] font-medium">
			<a rel="noopener" target="_blank" href="#preambule">Préambule</a>
			<a rel="noopener" target="_blank" href="#objet">Objet</a>
			<a rel="noopener" target="_blank" href="#definitions">Définitions</a>
			<a rel="noopener" target="_blank" href="#objet2">Objet</a>
			<a rel="noopener" target="_blank" href="#acceptation-et-modifications-des-cgv">
				Acceptation et modifications des CGV
			</a>
			<a rel="noopener" target="_blank" href="#duree-entree-en-vigeur">
				Durée - Entrée en vigueur
			</a>
			<a rel="noopener" target="_blank" href="#description-des-services">
				Description des services
			</a>
			<a rel="noopener" target="_blank" href="#fourniture-des-services">
				Fournitures des services
			</a>
			<a rel="noopener" target="_blank" href="#souscription-aux-services">
				Souscription aux services
			</a>
			<a rel="noopener" target="_blank" href="#engagement-au-titre-de-l'utilisation-des-services">
				Engagement au titre de l’utilisation des services
			</a>
			<a rel="noopener" target="_blank" href="#paiement">Paiement</a>
			<a rel="noopener" target="_blank" href="#droit-de-retractation">
				Droit de rétractation
			</a>
			<a rel="noopener" target="_blank" href="#responsabilite">Responsabilité</a>
			<a rel="noopener" target="_blank" href="#suspension-resiliation">
				Suspension/Résiliation
			</a>
			<a rel="noopener" target="_blank" href="#donnees-personnelles">
				Données personnelles
			</a>
			<a rel="noopener" target="_blank" href="#assistances-reclamations">
				Assistance et réclamations
			</a>
			<a rel="noopener" target="_blank" href="#force-majeure">Force majeure</a>
			<a rel="noopener" target="_blank" href="#cession">Cession</a>
			<a rel="noopener" target="_blank" href="#survivance">Survivance</a>
			<a rel="noopener" target="_blank" href="#nulite">Nullité - Titre</a>
			<a rel="noopener" target="_blank" href="#droit-applicable">
				Droit applicable et tribunaux compétents
			</a>
		</div>
	);
};

export default Links;
