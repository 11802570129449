import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";
import { CookiesProvider } from "CookiesContext";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTMID,
    // auth: 'HRlZRwHpRy0EDLVsf6_opg',
    preview: 'env-1',
    events: {
        consentUpdated: 'consentUpdated'
    },
    dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
