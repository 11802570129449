import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../sharedComponents/Button";

const UiManagement = ({buttonId}) => {
  const liList = [
    "Un logiciel intuitif disponible sur ordinateur, tablette & smartphone",
    "Un tableau de bord pour visualiser rapidement toute votre activité",
    "Une gestion simple et centralisée de vos casiers",
    "Affectation d'un rôle à un utilisateur en fonction de ses droits",
    "Un historique consultable à tout moment",
  ];
  let navigate = useNavigate()
  return (
    <div className="md:grid md:grid-cols-2 grid-rows-auto lg:max-w-[1320px]">
      {" "}
      <div className="xl:m-auto md:col-span-2 px-[36px] lg:px-[32px] md:text-center xl:w-[940px]">
        <p className="subtitle text-[#3798D4] font-semibold mb-[8px] lg:p-0">
          Interface de gestion
        </p>
        <h2 className="mb-[18px] md:mb-[40px] lg:mb-[24px]">
          Une gestion à distance de vos clés via notre logiciel Acses Manager
        </h2>
        <div className="lg:block xl:row-start-2 mb-[40px]">
          <p className="font-medium lg:font-normal mb-[20px] ">
            Grâce à notre logiciel Acses, gérez vos clés à distance en toute
            simplicité. Quel que soit l’endroit où vous vous trouvez, <strong>suivez en
            temps réel les échanges de clés</strong> de vos collaborateurs et de vos
            clients. Acses Manager est disponible sur différents supports : <strong>ordinateur, mobile & tablette</strong>.
            
          </p>
        </div>
      </div>
      <div className="mb-[44px] xl:mb-[80px]">
        <div>
          <img
            className="md:hidden"
            src="/assets/images/activitySector/ui-management-mobile.webp"
            alt=""
            width={630}
            height={185}
          />
        </div>
        <div>
          <img
            className="hidden md:flex lg:hidden"
            src="/assets/images/activitySector/ui-management-tablette.webp"
            alt=""
            width={453}
            height={396}
          />
        </div>
        <div>
          <img
            className="hidden lg:flex xl:hidden"
            src="/assets/images/activitySector/ui-management-desktop.webp"
            alt=""
            width={465} height={383}
          />
        </div>
        <div>
          <img
            className="hidden xl:flex"
            src="/assets/images/activitySector/ui-management-desktop-large.webp"
            alt=""
            width={656} height={353}
          />
        </div>
      </div>
      <div className="px-[36px] md:pt-[20px] mb-[36px] font-medium xl:ml-[109px] xl:w-[550px] ">
        <ul style={{listStyleType:"disc"}} className="flex flex-col gap-[8px] text-[#506F83] ">
          {liList.map((text) => (
            <li className="mx-[15px]" key={text}>
              {text}
            </li>
          ))}
        </ul>
      </div>
      <div className="md:col-span-2 flex justify-center">
      <Button
					text="Demander une démo"
					idBtn={buttonId}
					action={()=> navigate("/demande-demo")}
				/>
      </div>
    </div>
  );
};

export default UiManagement;
