import React from "react";
import { Link } from "react-router-dom";
import { ContactCards } from "../../../../sharedComponents";
import socialNetworks from "../../../../sharedComponents/socialNetworks";
const ContactInfos = () => {
	return (
		<section className="flex flex-col md:flex-row lg:flex-col items-center md:mb-[90px] md:gap-x-[32px] lg:order-4 lg:-mt-[100px]">
			<div className="flex flex-col gap-[20px] xl:gap-x-[31px] xl:gap-y-[36px] lg:mb-[119px] lg:mt-[24px] w-full ">
				<div className="flex flex-col md:flex-row lg:flex-col gap-[20px] ">
					<ContactCards
						imageStyle="w-[30.59px]"
						imageScr={"/assets/images/pictos/box.svg"}
						message="Envoyez-nous un email"
						contactInfo="contact@acses.io"
						// eslint-disable-next-line react/style-prop-object
						className="flex items-center w-full lg:max-w-[299px] p-[24px] bg-white"
					/>
					<ContactCards
						imageStyle="w-[31.01px]"
						imageScr={"/assets/images/pictos/headphones.svg"}
						message="Appelez-nous"
						contactInfo="09 80 80 05 51"
						className="flex items-center w-full lg:max-w-[299px] p-[24px] bg-white"
					/>
				</div>

				<div className="flex flex-col md:items-center lg:items-start lg:flex-col w-full gap-[20px] ">
					<div className="flex flex-col md:mb-0  md:w-[265px] md:text-center lg:text-left ">
						<p>21 Rue Médéric</p>
						<p>92110 Clichy</p>
					</div>
					<div className="flex justify-between items-center w-[225px]">
						{socialNetworks.map((item) => (
							<a href={item.link} key={item.id} className={item.style} target="_blank" rel="noopener">
								<img
									className="w-full h-full cursor-pointer"
									src={item.url}
									alt=""
								/>
							</a>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactInfos;
