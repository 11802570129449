import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../../sharedComponents/Button";
import i18next from "i18n";
import { Trans, useTranslation } from "react-i18next";
import en from "./NewsLetterForm-i18n/en";
import fr from "./NewsLetterForm-i18n/fr";

i18next.addResourceBundle("en", "NEWSLETTER", en);
i18next.addResourceBundle("fr", "NEWSLETTER", fr);



const NewsLetterForm = ({ parentStyleCustom }) => {
	const { t } = useTranslation("NEWSLETTER");
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [messageForm, setMessageForm] = useState("");
	const [messageStyle, setMessageStyle] = useState("");
	const [success, setSuccess] = useState(false)

	const onSubmit = async (dataForm) => {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				source: "Newsletter",
				...dataForm,
				newsletter: true,
			}),
		};

		fetch("https://api-v2.acses.io/web/contact/source", options)
			.then((res) => {
				if (res.status === 200) {
					setMessageStyle("text-[#33A620]")
					setSuccess(true);
					console.log(res.data)
					setMessageForm(
						"Email envoyé avec succès"
					);
				} else if (res.status === 500) {
					setMessageForm(
						"Une erreur est survenue, veuillez réessayer ultérieurement"
					);
					setTimeout(() => setMessageForm(""), 500)
				} else {
					setMessageForm(
						"Une erreur est survenue, veuillez réessayer ultérieurement"
					);
					setTimeout(() => setMessageForm(""), 500)
				}
			})
			.catch((err) => console.log(err));
		reset();
	};

	return (
		<div
			className={`flex justify-center  bg-[#F6F8FC] ${parentStyleCustom} `}
		>
			<div className="mx-[36px] w-full justify-between py-[24px] lg:mx-[32px] lg:flex lg:max-w-[960px] lg:py-[40px] xl:max-w-[1320px]">
				<div className="mb-[20px] flex items-center lg:mb-0 xl:mr-[211px]">
					<h3 className="text-center text-[21.25px] font-semibold text-[#163F59] md:text-left xl:text-[34px]">
						{t("H3")}
					</h3>
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="mb-[24px] flex flex-col items-center md:items-start md:flex-row lg:mb-0 xl:items-end gap-[16px] "
				>
					<div className="w-full">
						<input
						{...register("email", {
							required: true,
							pattern:
								/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
						})}
						className=" border[#163F59] mb-[8px] flex h-[48px] w-full justify-center rounded-lg border pl-[16px] text-[#506F83] md:mb-0 md:mr-[8px] lg:min-w-[354px] xl:max-w-[426px]"
						placeholder={t("PLACEHOLDER")}
					/>
					{errors?.helpMotif?.type === "required" && (
						<p className="text-[#EE2D2D]">
							{t("ERROR_FORM")}
						</p>
					)}
						<span
							className={
								messageStyle +
								" mb-[36px] mt-[8px] font-semibold leading-[24px]"
							}
						>
							{!success && messageForm && messageForm}
						</span>
					</div>
					

					<Button
						type="submit"
						text={t("TEXT_BTN")}
						idBtn="footer-newsletter"
						addStyle="w-full md:max-w-[103px] lg:mb-auto"
					/>
				</form>
				<span
					className={
						messageStyle +
						" mb-[36px] mt-[8px] font-semibold leading-[24px]"
					}
				>
					{success && messageForm}
				</span>
			</div>
		</div>
	);
};

export default NewsLetterForm;
