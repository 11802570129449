import React from "react";

const Story = () => {
  return (
    <div className="text-white lg:flex lg:max-w-[1320px] lg:items-stretch lg:gap-[32px] ">
      <div className="lg:w-1/2">
        <p className="mb-[16px] font-bold text-white ">
          Tout commence à l’occasion d’une préparation de voyage de longue
          durée. Cédric Schwartzenberger met son appartement en location et
          donne rdv au locataire pour réaliser la remise des clés. La difficulté
          de déposer et récupérer les clés devient vite une réalité voire un
          véritable casse-tête.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          Cédric a un avion à prendre, son interlocuteur a, quant à lui, du
          retard…
        </p>
        <p className="mb-[16px] font-medium text-white ">
          Comment remettre les clés sans manquer son vol ? A quelle personne de
          confiance remettre les clés ? Comment s’assurer de la récupération des
          clés à son retour ? Régulièrement confronté à cette problématique lors
          de ses voyages, Cédric a rapidement réalisé que la remise des clés
          était souvent une tâche fastidieuse et contraignante pour les
          locataires, tout en représentant un risque de sécurité d’accès aux
          biens pour les propriétaires.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          L’émergence de plateformes comme Airbnb a largement contribué à la
          nécessité de proposer une solution de remise et retrait de clés qui
          serait automatisée. Pas de main à main, mais en permettant de stocker
          les clés dans une machine sécurisée à proximité du lieu d’habitation.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          C’est ainsi que Cédric a eu l’idée, en détournant le fonctionnement
          des casiers de vestiaires de piscine, de proposer un premier service
          de mise à disposition de casiers à clé dans des lieux publics
          fréquentés. Son projet recueille un fort intérêt auprès de clients
          potentiels et d’investisseurs puisque la solution est à la fois
          pratique, innovante et en phase avec les besoins avérés de l’époque.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          Entrepreneur dans l’âme, Cédric lance alors Monkey Locky en 2016. Un
          service d’échanges de clés, s’appuyant sur un réseau de casiers
          installés dans les commerces de proximité, et destiné au grand public
          et notamment à une clientèle de voyageurs.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          Cette première expérience étant un succès, son goût pour les
          challenges le pousse à aller au-delà de simple prestataire de service
          et à maîtriser toute la chaîne de valeur du métier.
        </p>
      </div>
      <div className="lg:w-1/2">
        <p className="mb-[16px] font-medium text-white ">
          De nouvelles opportunités font alors apparition, accélérées par la
          crise du COVID-19 qui, avec le télétravail et la distanciation
          sociale, a considérablement modifié les usages des professionnels
          comme des particuliers. Les entreprises ont plus que jamais besoin de
          faciliter les échanges de clés que ce soit pour les employés, pour
          leurs prestations externes, ou plus particulièrement leurs clients.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          L'accès aux locaux publics ou privés, aux biens immobiliers ou aux
          véhicules reste nécessaire pour poursuivre le fonctionnement
          économique en France et générer des revenus au sein des sociétés.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          Personne ne peut se permettre de perdre du temps ou de l’argent. Et le
          travail à distance, l'absence de contacts ou de suivi des clés, les
          rotations des équipes, ne simplifient pas les rencontres physiques à
          des heures et des lieux fixes. C’est ainsi que Cédric et son équipe,
          en partenariat avec des clients et des industriels, ont voulu inventer
          leur propre technologie de casiers connectés.
        </p>
        <p className="mb-[16px] font-medium text-white ">
          {" "}
          L’ambition était de répondre à ces nouveaux usages de la ville
          connectée dites Smart city, et de permettre aux entreprises de
          s’adapter à la nécessité de digitaliser et d’automatiser leur parcours
          client.
        </p>
        <p className="mb-[16px] font-medium text-white">
          La solution serait facile à utiliser, totalement personnalisable,
          pourrait être directement raccordée aux différents logiciels existants
          et serait fabriquée en France au juste prix. Le déploiement d'une
          marque dédiée à la création et fourniture de solutions de casiers
          connectés BtoB, prend tout son sens.
        </p>
        <p className="mb-[16px] font-medium text-white">
          Acses voit le jour en 2020. Depuis, la start-up a bien grandi !
          Nouveaux locaux, nouveaux collaborateurs, nouvelles fonctionnalités.
          En 2023, une première levée de fonds va permettre de poursuivre le
          développement et l’innovation dans la conception des produits. La
          marque ACSES souhaite élargir sa portée à de nouveaux marchés et
          renforcer sa présence à l'international.
        </p>
        <p className="mb-[16px] font-medium text-white">
          Enfin, cette levée de fonds s’avère un fer de lance pour accroître la
          capacité de production et ainsi, répondre à la demande croissante de
          casiers intelligents.
        </p>
      </div>
    </div>
  );
};

export default Story;
