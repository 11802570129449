import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Custom = () => {
	const dataCard = [
		{
			id: uuidv4(),
			imgSrc: "/assets/images/pictos/pot-paint.svg",
			imgWidth: 40,
			imgHeight: 44,
			title: "Habillage de votre armoire connectée",
			textContent: (
				<p className="text-[#506F83] xl:w-[484px]">
					<Link to="/consigne-personnalisee">
						<span className="text-[#3798D4] underline">
							Personnalisez l’habillage de vos casiers 
						</span>
					</Link> aux couleurs de votre entreprise et gagnez en
					visibilité. Notre graphiste vous accompagne tout
					au long de votre projet pour concevoir un casier
					qui vous ressemble.
				</p>
			),
		},
		{
			id: uuidv4(),
			imgSrc: "/assets/images/pictos/screen-pen.svg",
			imgWidth: 44,
			imgHeight: 40,
			title:
				"Parcours utilisateur des écrans de vos consignes sécurisées",
			textContent: (
				<p>
					Nous définissons ensemble et personnalisons les
					champs de saisie intégrés sur les écrans tactiles
					de vos choix. Vos fonctionnalités métier sont
					ainsi adaptées au parcours que vous proposez à vos
					destinataires.
				</p>
			),
		},
	];
	return (
		<div className="w-full lg:max-w-[960px] xl:max-w-[1320px]">
			<section className="xl:m-auto md:col-span-2 lg:text-center xl:w-[940px]">
				<p className="text-[#3798D4] text-[1rem] md:text-[1.25rem] lg:text-[1.176rem] font-semibold mb-[8px] lg:p-0">
					Personnalisation
				</p>
				<h2 className="md:mb-[20px] lg:mb-[24px]">
					Personnalisez votre armoire électronique
				</h2>
				<div className="lg:block xl:row-start-2 mb-[36px]">
					<p className="font-medium lg:font-normal mb-[20px] leading-[24px] lg:leading-[32px] xl:lg:leading-[26px]">
						Différentes options de personnalisation
						s’offrent à vous :
					</p>
				</div>
			</section>
			<div className="flex flex-col md:flex-row gap-[20px] lg:gap-[32px]">
				{dataCard.map((data) => (
					<div
						key={data.id}
						className="tinyShadow !rounded-[16px] lg:rounded-[24px] px-[36px] md:px-[42px] py-[40px] lg:py-[56px] w-full"
					>
						<div className="flex justify-center mb-[24px]">
							<img src={data.imgSrc} alt="" width={data.imgWidth} height={data.imgHeight} />
						</div>
						<div className="flex flex-col items-center text-center">
							<h3 className="font-semibold mb-[8px] md:w-[248px] w-full xl:w-[500px]">
								{data.title}
							</h3>
							{data.textContent}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Custom;
