import React from "react";
import Articles from "./components/Articles/Articles";
import Contact from "./components/Contact/Contact";
import Links from "./components/Links/Links";
import { Helmet } from "react-helmet";

const LegalNotice = () => {
	return (
		<div className="flex flex-col items-center">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Acses | Mentions légales - Contrôle d'accès -
					Consignes sécurisées
				</title>
				<meta
					name="description"
					content="Accédez aux mentions légales de notre site acses.io > Toutes les données légales de notre start-up spécialisée dans la sécurisation de vos clés."
				/>
			</Helmet>
			<div className="w-full pt-[104px] lg:pt-[258px] xl:pt-[263px] lg:max-w-[960px] xl:flex xl:max-w-[1320px] xl:gap-[59px]">
				<Links />
				<Articles />
			</div>
			<div className="flex w-full justify-center bg-[#F6F8FC] py-[48px] px-[36px] lg:px-[32px]">
				<Contact />
			</div>
		</div>
	);
};

export default LegalNotice;
