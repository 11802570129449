import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../../sharedComponents/Button";

const Security = () => {
	let navigate = useNavigate();

	const dataCard = [
		{
			id: uuidv4(),
			imgSrc: "/assets/images/pictos/burglar-proof.svg",
			title: "Métal anti-effraction",
			textContent:
				"Nos consignes sont fabriquées en métal anti-effraction et peuvent résister à une pression de plus de 250 kg.",
			width: 48,
			height: 48,
		},
		{
			id: uuidv4(),
			imgSrc: "/assets/images/pictos/multilingue.svg",
			title: "Écran tactile 10 pouces multilingue",
			textContent:
				"Chaque consigne dispose d’un écran tactile 10 pouces multilingue pour guider au mieux les utilisateurs.",
			width: 48,
			height: 48,
		},
		{
			id: uuidv4(),
			imgSrc: "/assets/images/pictos/locker-gradient.svg",
			title: "Serrures électroniques brevetées",
			textContent:
				"Tous les casiers d’une consigne sont individuellement compartimentés et équipés de serrures électroniques sécurisées.",
			width: 48,
			height: 48,
		},
	];

	return (
		<div className="lg:max-w-[960px] xl:max-w-[1320px]">
			<div className="mb-[20px] md:mb-[36px] lg:mb-[48px] lg:text-center xl:mb-[36px]">
				<div className="mb-8px">
					<p className="subtitle font-semibold text-[#3798D4]">
						Comment ça marche ?
					</p>
				</div>
				<div className="mb-[43px] justify-center lg:flex">
					<h2 className="xl:w-[767px]">
						Sécurisation de vos biens grâce à nos consignes
						Made in France
					</h2>
				</div>
				<div className="hidden gap-y-[20px] md:mb-[36px] lg:flex lg:flex-col lg:items-center">
					<p className=" mb-[5px] font-normal text-[#506F83] md:font-medium lg:font-normal lg:leading-[32px] xl:w-[869px]">
						{" "}
						Nous accordons une grande importance à la
						fabrication de nos consignes, aussi bien dans le
						choix de nos fournisseurs que dans la qualité de
						leur réalisation. Nous suivons chaque étape de
						leur fabrication. C’est pour cette raison que
						nos casiers sont fabriqués en France puis montés
						dans nos ateliers parisiens.
					</p>
				</div>
			</div>
			<div
				id="cardContainer"
				className="mb-[36px] flex flex-col items-center gap-[24px] md:mb-[60px] md:flex-row xl:mb-[72px] xl:gap-[33px]"
			>
				{dataCard.map((data) => (
					<div
						key={data.id}
						id="card"
						className="tinyShadow w-[288px] px-[16px] py-[28px] md:h-[338px] md:w-1/3 md:py-[32px] lg:px-[19px] lg:py-[56px] xl:px-[35px]"
					>
						<div className="mb-[14px] flex justify-center">
							<img
								src={data.imgSrc}
								alt=""
								width={data.width}
								height={data.height}
							/>
						</div>
						<div className="text-center">
							<p className="mb-[8px] font-bold text-[#163F59]">
								{data.title}
							</p>
							<p className="small-text text-[#506F83]">
								{data.textContent}
							</p>
						</div>
					</div>
				))}
			</div>
			<div className="flex justify-center">
				<Button
					text="Découvrez nos gammes de casiers sécurisés"
					idBtn="bouton-ccm-decouvrez-casiers-securise"
					action={() => navigate("/solutions-connectees")}
				/>
			</div>
		</div>
	);
};

export default Security;
