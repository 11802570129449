import React from 'react'
import NavBar from "../../sharedComponents/NavBar/NavBar"
import NewFooter from '../../sharedComponents/NewFooter/NewFooter'

const MainLayout = ({children}) => {
  return (
    <>
    <NavBar />
    <div>{children}</div>
    <NewFooter />
    </>
  )
}

export default MainLayout