import React from "react";
import { useNavigate } from "react-router-dom";

const CustomMasterKey = () => {
	let navigate = useNavigate();
	return (
		<div className="relative justify-between overflow-hidden rounded-[16px] bg-[#E7F3FA] p-[20px] md:flex md:h-[252px] md:gap-[36px]  md:py-[36px] lg:h-[384px] lg:px-[80px] lg:py-[66px] ">
			<div className="filterBlur absolute -bottom-[130px] h-[270px] w-[270px] md:right-0 md:bottom-0 lg:-top-[50px]"></div>
			<div className="md:w-[292px] lg:w-[473px]">
				<div className="md:flex md:flex-col md:items-start ">
					<h2 className="z-[10] mb-[36px] font-semibold leading-[32px] text-[#163F59] lg:leading-[48px] ">
						Vous souhaitez personnaliser votre Keymaster ?
					</h2>
				</div>
				<button
					onClick={() =>
						navigate("/consigne-personnalisee")
					}
					id="bouton-nsc-personnaliser-consigne"
					className="relative z-[10] flex h-[64px] items-center rounded-[8px] bg-white py-[12px] pl-[16px] pr-[20px] text-left md:w-[292px] lg:mb-[67px] lg:h-[74px] lg:w-auto lg:gap-[20px] lg:p-[20px] "
				>
					<p className="rounded-[8px] bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text font-bold text-transparent ">
						Créer mon keymaster personnalisé
					</p>
					<img
						src="/assets/images/pictos/arrow-rigth-gradient.svg"
						alt="Arrow"
						width={9}
						height={16}
						loading="lazy"
					/>
				</button>
			</div>
			<div className="relative -bottom-[80px] -right-[10px] md:-bottom-[6px] md:w-auto lg:bottom-0 lg:-right-[80px] xl:-right-[20px]">
				<img
					className="w-full md:hidden"
					src="/assets/images/solutions/custom.webp"
					alt="consignes à clés personnalisées"
					width={332}
					height={266}
					loading="lazy"
				/>
				<img
					className="hidden w-full md:inline lg:hidden"
					src="/assets/images/solutions/custom-tablette.webp"
					alt="consignes à clés personnalisées"
					width={338}
					height={216}
					loading="lazy"
				/>
				<img
					className="hidden w-full lg:inline xl:hidden"
					src="/assets/images/solutions/custom-desktop.webp"
					alt="consignes à clés personnalisées"
					wwidth={331}
					height={347}
					loading="lazy"
				/>
				<img
					className="hidden w-full xl:inline"
					src="/assets/images/solutions/custom-desktop-large.webp"
					alt="consignes à clés personnalisées"
					width={570}
					height={398}
					loading="lazy"
				/>
			</div>
		</div>
	);
};

export default CustomMasterKey;
