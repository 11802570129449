const locale ={
  H3:`Specially<1></1> designed to <4></4> manage your keys <7></7> with ease`,
  WITH_MAEVA:`We want to provide a practical and easy service to our
  customers and our owners but certainly not replace the
  human contact, which is a real spearhead at Maeva.`,
  MAEVAHOME:"Marketing and Customer Experience Manager, <br/> at MaevaHome",
  H3_ADOPT:"<0>Do you want to adopt our solution <1></1> to make your business easier?</0>",
  TYPE_MESSAGE_1:"Send us an email",
  EMAIL:"contact@acses.io",
  TYPE_MESSAGE_2:"Call us",
  PHONE:"09 80 80 05 51"

}

export default locale