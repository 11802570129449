import React from "react";
import "./Hero.scss"

const Hero = () => {
	return (
		<div>
			<div className="mb-[71px]">
				<div className="relative mb-[45px] px-[36px] pt-[36px] md:flex md:flex-col md:items-start md:text-center lg:items-center">
					<p className="subtitle mb-[8px] font-semibold text-[#3798D4] md:w-full">
						Notre histoire
					</p>
					<h1 className="md:mb-[20px] lg:mb-[36px] lg:w-[952px] xl:w-[1154px] ">
						Booster la croissance des entreprises en leur
						permettant un service 24h/7j
					</h1>
				</div>
				<div>
					<img
						className="w-full md:hidden"
						src="/assets/images/ourStory/ourstory-mobile.png"
						alt=""
						loading="lazy"
					/>
					<img
						className="hidden w-full md:flex lg:hidden"
						src="/assets/images/ourStory/ourstory-tablette.png"
						alt=""
						loading="lazy"
					/>
					<img
						className="hidden w-full lg:flex xl:hidden "
						src="/assets/images/ourStory/header.webp"
						alt=""
						loading="lazy"
					/>
					<img
						className="hidden w-full xl:flex"
						src="/assets/images/ourStory/header.webp"
						alt=""
						loading="lazy"
					/>
				</div>
			</div>
		</div>
	);
};

export default Hero;
