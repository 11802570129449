import React from "react";
import { useNavigate } from "react-router-dom";

const KnowMore = ({ buttonId, buttonId2 }) => {
  let navigate = useNavigate();
  return (
    <div className="relative overflow-hidden flex flex-col lg:flex-col md:items-center justify-center bg-gradient-to-r from-[#2DBBED] to-[#2972B8] rounded-2xl py-[40px] px-[36px] lg:px-[32px] md:p-[40px] w-full lg:max-w-[960px] xl:max-w-[1320px]">
      <div className="z-[1] mb-[32px] md:mb-0 ">
        <h2 className="text-[#FFFFFF] md:mb-[40px] text-center xl:text-left">
          Vous souhaitez en savoir plus ?
        </h2>
      </div>
      <div className="hidden absolute left-0">
        <img
          className="scale-[1.2]"
          src="/assets/images/activitySector/logo-acses-background.svg"
          alt=""
        />
      </div>
      <div className="z-[1] md:flex md:flex-col gap-[20px] ">
        <div className="flex justify-center">
          <div className="flex justify-center items-center bg-white rounded-lg py-[16px] px-[36px] mb-[24px] lg:w-[390px] lg:h-[64px]">
            <div className="mr-[17px]">
              <img
                className="w-[29px]"
                src="/assets/images/pictos/phone-gradient-blue.svg"
                alt="Téléphone"
                width={29}
                height={29}
              />
            </div>
            <button onClick={()=>{navigate("/contact")}} className="" id={buttonId}>
              <p className="text-transparent bg-clip-text bg-gradient-to-r from-[#2DBBED] to-[#2972B8] font-bold">
                {" "}
                Contacter un commercial
              </p>
            </button>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={() => navigate("/consigne-personnalisee")}
            id={buttonId2}
            className="flex items-center gap-[20px] md:w-[331px] text-white font-bold "
          >
            <p className="text-[#FFFFFF] leading-[16px] md:leading-[28px]">
              {" "}
              Découvrez toutes nos options de personnalisation
            </p>{" "}
            <img
              className="w-[8px]"
              src="/assets/images/pictos/arrow-button.svg"
              alt=""
              width={8}
              height={10}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default KnowMore;
