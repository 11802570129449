import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../sharedComponents/Card";
import Button from "../../../../sharedComponents/Button";

const Step1 = () => {
	const navigate = useNavigate();
	
	return (
		<Card
			img={
				<>
					<img
						className="md:hidden"
						src="/assets/images/myCustomMasterKey/gamme-mobile.webp"
						alt="casiers modulaires et sécurisés"
						loading="lazy"
					/>
					<img
						className="hidden md:flex lg:hidden"
						src="/assets/images/myCustomMasterKey/gamme-tablette.webp"
						alt="casiers modulaires et sécurisés"
						loading="lazy"
					/>
					<img
						className="hidden lg:flex xl:hidden relative -bottom-[40px]"
						src="/assets/images/myCustomMasterKey/gamme-desktop2.webp"
						alt="casiers modulaires et sécurisés"
						loading="lazy"
					/>
					<img
						className="hidden xl:flex relative -bottom-[40px]"
						src="/assets/images/myCustomMasterKey/gamme-desktop-large.webp"
						alt="casiers modulaires et sécurisés"
						loading="lazy"
					/>
				</>
			}
			subtitle="Casiers modulaires"
			title="Combien de casiers souhaitez-vous ?"
		>
			<div className="">
				{" "}
				<div className="md:flex mb-[20px]">
					<p className="text-[#163F59] font-semibold mb-[8px] md:w-1/3 lg:w-[248px]">
						Notre solution est totalement modulaire :
					</p>{" "}
					<div className="tinyShadow flex items-center px-[16px] py-[20px] w-[224px] font-medium h-[64px]">
						<p className="text-[#163F59]">
							De{" "}
							<span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#3798D4] to-[#2970B7] mx-[5px]">
								{" "}
								5 à 150{" "}
							</span>
							casiers
						</p>
					</div>
				</div>{" "}
				<div>
					<ul className="flex flex-col md:gap-[24px] mb-[20px] pl-[25px] lg:px-[32px] lg:pl-[20px]">
						<li
							style={{
								listStyleImage:
									"url('assets/images/pictos/blue-tcheck.svg')",
							}}
							className="small-text pl-[10px]"
						>
							Ajoutez des extensions de casiers grâce à un
							simple branchement par câble Ethernet.
						</li>
						<li
							style={{
								listStyleImage:
									"url('assets/images/pictos/blue-tcheck.svg')",
							}}
							className="small-text pl-[10px]"
						>
							Votre projet évolue dans le temps ? Déplacez
							facilement vos extensions de casiers.
						</li>
					</ul>
					<div className="flex flex-col md:flex-row md:items-center items-start gap-[16px]">
						<Button
							text="Obtenir un devis"
							action={() => navigate("/demande-devis")}
						/>
						<Button
							text="Contacter un commercial"
							action={() => navigate("/contact")}
							reverseStyle
						/>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default Step1;
