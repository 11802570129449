import { v4 as uuidv4 } from "uuid";

const socialNetworks = [
	// {
	// 	id: uuidv4(),
	// 	style: "w-[20px] h-[20px]",
	// 	url: "/assets/images/logos/social-networks/linkedin-dark.svg",
	// 	link:""
	// },
	{
		id: uuidv4(),
		style: "w-[20px] h-[20px]",
		url: "/assets/images/logos/social-networks/linkedin-dark.svg",
		link:"https://www.linkedin.com/company/acses-casiers-connect%C3%A9s/?original_referer="
	},
	{
		id: uuidv4(),
		style: "w-[24.6px] h-[20px]",
		url: "/assets/images/logos/social-networks/twitter-dark.svg",
		link:"https://twitter.com/AcsesFrance"
	},
	{
		id: uuidv4(),
		style: "w-[29.98px] h-[20px]",
		url: "/assets/images/logos/social-networks/youtube-dark.svg",
		link:"https://www.youtube.com/@acses867"
	},
];

export default socialNetworks;
