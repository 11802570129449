import Hero from "./components/Hero/Hero";
import React from "react";
import Security from "./components/Security/Security";
import Connected from "./components/Connected/Connected";
import KeyMaster from "./components/KeyMaster/KeyMaster";
import CustomMasterKey from "./components/CustomMasterKey/CustomMasterKey";
import Totem from "./components/Totem/Totem";
import Tailored from "./components/Tailored/Tailored";
import HowToDrive from "./components/HowToDrive/HowToDrive";
import Actions from "./components/Actions/Actions";
import ContactUs from "./components/ContactUs/ContactUs";
// import CardImg from "./components/CardImg";
import Characteristics from "./components/Characteristics/Characteristics";
import HowToDriveDesktop from "./components/HowToDriveDesktop/HowToDriveDesktop";
import { Helmet } from "react-helmet";

const Solutions = () => {
	return (
		<div id="1">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Casiers connectés gérés à distance | Acses
				</title>
				<meta
					name="description"
					content="La gestion de vos clés relève du défi logistique ? 👉 Découvrez nos consignes sécurisées et gérées via notre logiciel sur mobile, ordinateur et tablette."
				/>
			</Helmet>
			<section className="flex flex-col items-center pt-[128px]  md:pt-[152px] lg:pt-[226px] xl:pt-[240px]">
				<Hero />
				<div className="flex w-full items-end justify-center overflow-hidden md:hidden ">
					<img
						className="-z-[2000] -mb-[75px] w-full xs:hidden"
						src="/assets/images/solutions/header-mobile.webp"
						alt="consignes sécurisées en métal made in France"
						width={360}
						heigth={306}
						
					/>
					<img
						className="-z-[2000] -mb-[75px] hidden w-full xs:flex"
						src="/assets/images/solutions/header.webp"
						alt="consignes sécurisées en métal made in France"
						width={360}
						heigth={306}
						
					/>
				</div>
				<div className="flex justify-center">
					<div className="hidden items-end justify-center overflow-hidden md:flex lg:max-w-[960px] xl:max-w-[1320px] ">
						<img
							className="-mb-[120px] md:w-full xl:-mb-[200px]"
							src="/assets/images/solutions/header.webp"
							alt="consignes sécurisées en métal made in France"
							width={1222}
							height={622}
							
						/>
					</div>
				</div>

				<div className="flex justify-center md:px-[36px] lg:px-0">
					<Security />
				</div>
			</section>

			<section className="px-[32px] lg:mb-[96px] lg:flex lg:flex-col lg:items-center">
				<div className="w-full lg:flex lg:max-w-[952px] lg:flex-col lg:items-center xl:max-w-[1320px]">
					{" "}
					<div className="mb-[48px] md:mb-[84px] xl:mb-[192px]">
						<Connected />
					</div>
					<div className="mb-[60px] w-full max-w-[1320px] lg:mb-[48px] lg:gap-[30px] xl:mb-[96px]">
						<div className="hidden items-center text-center lg:flex lg:flex-col">
							<p className="subtitle text-[#3798D4]">
								Les consignes connectées
							</p>
							<h2 className="xl:mb-[80px]">
								Quelle consigne connectée vous faut-il ?
							</h2>
						</div>
						<div className="flex flex-col gap-[72px] md:flex-row md:flex-wrap md:items-end md:justify-between lg:gap-[32px]">
							<div>
								<KeyMaster
									imgSrc="/assets/images/solutions/K5.webp"
									title="Keymaster"
									ability="5 casiers"
									imgAlt="casier Keymaster 5"
									textButton="Demander un devis"
									buttonId="bouton-gauche-nsc-demander-un-devis"
									isFamous
									alt=""
								/>
							</div>{" "}
							<div>
								<KeyMaster
									imgSrc="/assets/images/solutions/K10.webp"
									title="Keymaster"
									ability="10 casiers"
									imgAlt="casier Keymaster 10"
									textButton="Demander un devis"
									buttonId="bouton-milieu-nsc-demander-un-devis"
								/>
							</div>{" "}
							<div>
								<KeyMaster
									imgSrc="/assets/images/solutions/extention.webp"
									title="Extension"
									ability="10 casiers"
									imgAlt="extension consigne à clés acses"
									textButton="Demander un devis"
									buttonId="bouton-droit-nsc-demander-un-devis"
								/>
							</div>
						</div>
					</div>
					<div className="w-full lg:max-w-[952px] xl:max-w-[1320px]">
						<Characteristics />
					</div>
				</div>
			</section>
			<section className="flex w-full justify-center px-[36px]">
				<div className="flex w-full flex-col gap-[60px] md:gap-[96px] lg:max-w-[952px] lg:gap-[164px] xl:max-w-[1320px] xl:gap-[128px]">
					<div className="">
						<div className="">
							<CustomMasterKey />
						</div>
					</div>
					<div className="">
						<Totem />
					</div>
					<div className="">
						<div className="">
							<Tailored />
						</div>
					</div>
				</div>
			</section>

			<div className="mb-[111px] overflow-hidden px-[36px] lg:hidden">
				<HowToDrive />
			</div>
			<div className="mb-[111px] hidden overflow-hidden px-[36px] lg:flex lg:justify-center">
				<HowToDriveDesktop />
			</div>
			<div className="items-center bg-[#F6F8FC] px-[36px] py-[40px] lg:py-[62px] xl:flex xl:flex-col">
				<Actions />
				<ContactUs />
			</div>
		</div>
	);
};

export default Solutions;
