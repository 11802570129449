
const Hero = () => {
	return (
		<div className="w-full lg:max-w-[960px] xl:max-w-[1320px] ">
			<div className="md:hidden">
				<img
					className="w-full"
					src="/assets/images/howItWorks/hero-mobile.webp"
					alt="gestion à distance des clés via un logiciel"
					loading="lazy"
				/>
			</div>
			<div className="hidden justify-center md:flex lg:hidden">
				<img
					className="w-full"
					src="/assets/images/howItWorks/hero-tablette.webp"
					alt="gestion à distance des clés via un logiciel"
					width={732}
					height={461}
					loading="lazy"
				/>
			</div>
			<div className="hidden w-full lg:flex lg:justify-center xl:hidden">
				<img
					className="w-full"
					src="/assets/images/howItWorks/hero-desktop.webp"
					alt="gestion à distance des clés via un logiciel"
					width={1128}
					height={635}
					loading="lazy"
				/>
			</div>
			<div className="hidden w-full lg:justify-center xl:flex">
				<img
					className="w-full"
					src="/assets/images/howItWorks/hero-desktop-large.webp"
					alt="gestion à distance des clés via un logiciel"
					width={1128}
					height={622}
					loading="lazy"
				/>
			</div>
			<div>
				<div className="mb-[16px] lg:mb-[36px] lg:flex lg:justify-center lg:text-center ">
					<h1 className="xl:w-[826px]">
						Comment fonctionnent nos consignes Acses ?
					</h1>
				</div>
				<div className="xl:flex xl:justify-center">
					<h3 className="mb-[16px] lg:mb-[20px] lg:text-center xl:w-[869px] ">
						Notre solution clé en main de{" "}
						<span className="">
							consignes sécurisées est modulable{" "}
						</span>{" "}
						et prête à être installée dans vos locaux !
						Choisissez le nombre de casiers (15 à 150) en
						fonction de vos besoins puis{" "}
						<span className="">
							contrôlez leur accès à distance{" "}
						</span>{" "}
						via notre logiciel Acses Manager.
					</h3>
				</div>
				<div className="xl:flex xl:justify-center">
					<p className="font-medium text-[#506F83] lg:text-center lg:font-normal xl:w-[869px]">
						Toute ouverture ou fermeture de casier est
						tracée et consultable en temps réel dans notre
						interface. Vous possédez déjà un logiciel de
						gestion interne ? Pas de soucis ! Grâce à notre
						API, nous connectons facilement votre système à
						nos casiers.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Hero;
