import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../sharedComponents/Button";

const KeyCabinet = ({ blueSpan, title, buttonId }) => {
  let navigate = useNavigate()
  const services = [
    {
      id: uuidv4(),
      imgScr: "/assets/images/pictos/finger-print.svg",
      imgStyle: "w-[36px]",
      alt: "",
      text: "Traçabilité de vos clés",
    },
    {
      id: uuidv4(),
      imgScr: "/assets/images/pictos/remote-hexagone.svg",
      imgStyle: "w-[36px]",
      alt: "",
      text: "Interface de gestion à distance",
    },
    {
      id: uuidv4(),
      imgScr: "/assets/images/pictos/padlock.svg",
      imgStyle: "w-[36px]",
      alt: "",
      text: "Métal anti-effraction",
    },
    {
      id: uuidv4(),
      imgScr: "/assets/images/pictos/headphones.svg",
      imgStyle: "w-[36px]",
      alt: "",
      text: "Support client 7j/7",
    },
  ];

  return (
    <div className="md:grid md:grid-cols-6 ">
      <div className="md:col-span-3">
        <div className="relative -left-[36px] mb-[20px] md:hidden">
          <img src="/assets/images/activitySector/gamme-acses-mobile.webp" alt="" width={340} height={212} />
        </div>
        <div className="hidden md:flex lg:hidden relative -left-[36px] mb-[36px]">
          <img src="/assets/images/activitySector/gamme-acses-tablette.webp" alt="" width={384} height={289}/>
        </div>
        <div className="hidden lg:flex xl:hidden relative -left-[36px] mb-[36px] lg:mb-[60px]">
          <img src="/assets/images/activitySector/gamme-acses-desktop.webp"alt="" width={622} height={449} />
        </div>
        <div className="hidden xl:flex relative -left-[36px] mb-[36px]">
          <img src="/assets/images/activitySector/gamme-acses-desktop-large.webp" alt="" width={975} height={543} />
        </div>
      </div>
      <div className="md:col-span-6 row-start-1">
        {" "}
        <p className="subtitle text-[#2DBCEE] md:text-center mb-[8px] lg:p-0">
          {blueSpan}
        </p>
        <h2 className="text-white lg:mx-auto  md:text-center lg:px-0 md:mb-[48px] lg:mb-[60px]">
          {title}
        </h2>
      </div>
      <div className="flex flex-col items-start md:justify-center md:col-span-3lg:justify-start gap-y-[24px] md:gap-x-[139px] lg:max-w-[1320px] mb-[40px] lg:mb-0 lg:ml-[36px]">
        {services.map((service) => (
          <div
            key={service.id}
            className="flex items-center justify-center gap-[22px]"
          >
            <div className={`${service.imgStyle} h-[44px]`}>
              {" "}
              <img
                className="w-full"
                src={service.imgScr}
                alt={service.alt}
                height={44}
                width={36}
              />{" "}
            </div>
            <div className="">
              <p className="text-white w-[180px] md:min-w-[331px] font-medium lg:font-semibold">
                {service.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex md:justify-center md:col-span-6">
      <Button
					text="Obtenir un devis"
					idBtn={buttonId}
					action={()=>navigate("/demande-devis")} 
				/>
      </div>
    </div>
  );
};

export default KeyCabinet;
