const locale ={
    H3:`Spécialement<1></1> conçu pour <4></4> gérer vos clés <7></7> en toute simplicité`,
    WITH_MAEVA:`Nous souhaitons apporter un service pratique et facile, à nos
    clients et nos propriétaires mais certainement pas remplacer le
    contact humain, qui est un véritable fer de lance chez Maeva.`,
    MAEVAHOME:"Responsable marketing et expérience clients, <br/> chez MaevaHome",
    H3_ADOPT:"<0>Vous souhaitez adopter notre solution <1></1> pour faciliter votre activité ?</0>",
    TYPE_MESSAGE_1:"Envoyer-nous un email",
    EMAIL:"contact@acses.io",
    TYPE_MESSAGE_2:"Appellez-nous",
    PHONE:"09 80 80 05 51"

}

export default locale