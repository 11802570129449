import React from "react";
import { ContactCards } from "../../../../sharedComponents/ContactCards";

const Contact = () => {
	return (
		<div className="w-full justify-between lg:flex  lg:max-w-[960px] lg:items-center xl:max-w-[1320px]">
			<h2 className="mb-[20px] text-center lg:w-[307px] lg:text-[1.953rem]">
				Contactez-nous
			</h2>
			<div className="flex w-full flex-col items-start gap-[20px] md:flex-row md:justify-center lg:max-w-[600px] lg:justify-end ">
				<ContactCards
					imageScr="/assets/images/pictos/box.svg"
					imageStyle="w-[30px]"
					message="Envoyez-nous un email"
					contactInfo="contact@acses.io"
					className="flex justify-start w-full p-[20px] max-w-[264px] lg:max-w-[288px]"
				/>
				<ContactCards
					imageScr="/assets/images/pictos/headphones.svg"
					imageStyle="w-[30px]"
					message="Appelez-nous"
					contactInfo="09 80 80 05 51"
					className="flex justify-start w-full p-[20px] max-w-[264px] lg:max-w-[288px]"
				/>
			</div>
		</div>
	);
};

export default Contact;
