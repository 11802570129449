import React, {useEffect} from "react";
import './HubspotContactForm.scss';
const HubspotContactForm = props => {
  const { region, portalId, formId } = props;
  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm'
        })
      }
    });
  }, []);

  return (
    <div className="flex justify-center items-center">
      <div  id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;